.black {
  fill: #000000 !important;
  &:hover{
    fill:#267E9C !important;
  }
}

.gray {
  fill: #dadada;
}

.white {
  fill: #FFFFFF !important;
}

.cyan {
  fill: #0067b1 !important;
}

.green{
  fill: #2ecc71 !important;
}

.red{
  fill: #e74c3c ;
}

.actionState:hover {
  fill: #267E9C !important;
}

.actionState:active {
  fill: #267E9C !important;
}

.mediumGray {
  fill: #b0b0b0;
}

.disabled {  
  cursor: not-allowed;
  fill: #e6e6e6;
}