.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  padding: 30px 0px 4px;
}

.bulletinDrawerContent {
  padding: 17px 0px 4px;
  word-break: break-word;
  .content {
    font-size: 14px;
  }
  .content > p {
    margin: 0;
  }
}

.image {
  width: 100%;
  margin-top: 16px;
  -o-object-fit: contain;
     object-fit: contain;
  max-height: 760px;
}

.securityItemDate {
  font-size: 10px;
  padding: 0px 4px;
}

.infoContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 4px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.authorContainer {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.authorImage {
  width: 24px;
  height: 24px;
  font-size: x-small;
  margin-right: 4px;
}

.productImage{
  width: 151px;
  height: 26px;
  fill: #22326E;
}

.authorName {
  font-size: 12px;
  padding: 4px;
  font-weight: 500;
}

.authorTitle {
  font-size: 12px;
}

.linkBulletin {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 7px 14px;
  margin-top: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  bottom: 20px;
  width: 698px;
  position: fixed;
  label {
    font-size: 14px;
    color: #000;
    line-height: 21px;
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.copyLink {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  cursor: pointer;
  height: 21px;
  width: 17px;
}

.productImageBackground{
  background-color: #e6e6e6;
  color: #7d7d7d;
}

@media only screen and (min-width: 768px) {
  .securityItemDate {
    -ms-flex-item-align: end;
        align-self: flex-end;
    margin-left: auto;
    padding: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .title {
    font-size: 21px;
  }

  .content {
    font-size: 16px;
  }

  .authorName {
    font-size: 14px;
  }

  .authorTitle {
    font-size: 14px;
  }

  .securityItemDate {
    font-size: 12px;
    -ms-flex-item-align: end;
        align-self: flex-end;
    margin-left: auto;
    padding: 0px;
  }
}
