.container {
  text-align: center;
  width: calc(100% - 40px);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 20px;
}

.scrollable {
  height: calc(100% - 49px);
  overflow-y: auto;
  overflow-x: hidden;
}

.plan {
  margin: 30px 32px;
}

.planCard {
  min-width: 200px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
  padding: 32px;
  background-size: cover;
  background-position: center;
}

.planCard.enterprise {
  background-image: url('/images/HomePage/identify-enterprise@2x.jpg');  
}

.planCard.basic {
  background-image: url('/images/HomePage/identify-basic-x.jpg');
  border-radius: 20px;
  min-height: 150px;
}

.planCardButton {
  margin-top: auto;
  height: 40px;
  font-size: 16px;
}

.planCardLabelBilling {
  font-size: 14px;
  color: #ffffff;
}
.buttonContainer {
  width: 360px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px;
}

.requestHelp {
  padding: 12px;
  font-weight: 500;
}

.featureCheckIcon {
  width: 17px;
  height: 16px;
  fill: #026ccf;
}

.featureLabel {
  padding: 0 8px;
  text-align: left;
  font-size: 14px;
}

.feature {
  padding: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.planCardLabel {
  padding: 4px 0;
  color: #ffffff;
}

.planCardName {
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  margin: -8px 0 -5px;
  color: #ffffff;
}

.planCardDescription {
  width: 80%;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}

.planPrice {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.planCardPrice {
  font-size: 36px;
  color: #ffffff;
}

.planCardPriceInterval {
  font-size: 18px;
  color: #ffffff;
}

.planCardStatus {
  min-width: 90px;
  height: 20px;
  padding: 0px 8px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.7;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
  background-color: #549c05;
  overflow: hidden;
  margin: 10px 0 0 15px;
}

.productStatusRed {
  color: #ffffff;
  background-color: #ca001a;
}

.productStatusGreen {
  color: #ffffff;
  background-color: #008000;
}

.productIconHeader {
  width: 271px;
  height: 60px;
  fill: #22326E;
}

.productDescription {
  font-size: 18px;
  margin: 18px 0px;
  text-align: center;
}

.dividerLineHeader {
  width: calc(100% + 200px);
  margin: 20px 0px;
  border-bottom: 1px solid #e6e6e6;
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 920px;
  }
  .planContainer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    & div:last-child {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
  }

  .planCard {
    min-width: 288px;
    padding: 40px;
    text-align: left;
  }

  .plan {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    border-radius: 10px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
  }

  .planCard.basic {
    background-image: url('/images/HomePage/identify-basic@2x.jpg');
    color: #ffffff;
    min-height: 250px;
    border-radius: 10px 0 0 10px;
  }

  .planCardName {
    margin: 47px 0 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .planCardLabel {
    padding: 4px 0;
  }

  .planCardStatus {
    margin: 0;
  }

  .featureLabel {
    padding: 0 8px;
    text-align: left;
    font-size: 16px;
  }

  .productIconHeader {
    width: 335px;
    height: 72px;
  }
}