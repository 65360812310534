
.cardMessage {
    text-align: left;
    margin: 0px 36px 0 5px;
    font-size: 14px;
    color: #212121;
    line-height: 1.5;
    -ms-flex-preferred-size: 85%;
        flex-basis: 85%;
}

.cardUpdateButtons {
    margin: 8px 0px 11px 0px;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
  
.cancelButton,
.removeButton,
.actionButton {
    font-weight: 500;
    padding: 4px 14px
}

.removeCardClose,
.updateCardClose {
    height: 14px;
    width: 14px;
}  

.updateCardContainer {
    margin: 16px 0 -10px -14px;
    background-color: #FFDFE2;
    padding: 14px 14px 5px;
    border-top: 2px solid #CA001A;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
}
  
.removeCardContainer {
    margin: 16px 0 -10px -14px;
    background-color: rgba(2, 108, 207, 0.1);
    padding: 14px 14px 5px;
    -webkit-box-flex:1;
        -ms-flex:auto;
            flex:auto;
}
  
.removeCardIcon {
    height: 18px;
    width: 18px;
    fill: #0067b1;
}
  
.updateCardIcon {
    height: 18px;
    width: 18px;
    fill: #CA001A
}

.warningText {
    color:#CA001A
}

@media only screen and (min-width: 480px) {  
    .removeCardContainer,.updateCardContainer {
      margin: 16px -14px -14px;
    }
  }