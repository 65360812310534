.textfieldFormContainer {
   width: 100%;
  }

.tellUsMoreLabel {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 32px;
  }

.detailsContainer {
    width: 100%;
    -webkit-box-align: left;
        -ms-flex-align: left;
            align-items: left;
    margin-bottom: 32px;
  }

.details {
    background-color: #fafafa;
    padding: 0 20px 20px 42px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }

.contactPersonContainer, .topicContainer, .categoryContainer {
    -webkit-box-align: left;
        -ms-flex-align: left;
            align-items: left;
    margin-bottom: 24px;
  }

.contactDropDown, .topicDropDown, .categoryDropDown {
    background-color: #ffffff;
  }

.labels {
    height: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    color: #4a4a4a;
}

.textArea {
    width: 100%;
    height: 144px;
    border-radius: 4px;
    border: solid 2px #cccccc;
    background-color: #ffffff;
  }

  @media only screen and (min-width: 768px) {  
    .details {
      padding: 0 42px;
    }
  }

  @media only screen and (max-width: 475px) {  
    .details{
      padding: 0 20px 20px 20px
    }
  }