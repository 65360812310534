.header {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 25px;
  border-bottom: 1px solid #e6e6e6;
}

.mainTitle {
  font-weight: bold;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 24px;
  text-align: center;
  color: #212121;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 759px;
  background-color: #ffffff;
  overflow-y: auto;
}

.area {
  margin-top: 17px;
  margin-bottom: 25px;
}

.supportaccess {
  margin-top: 20px;
  cursor: pointer;
}
