.container {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 90px;
}

.text {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.image {
  height: 300px;
  width: 300px;
}