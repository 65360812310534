.productName {
  font-size: 24px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #22326E;
  text-transform: uppercase;
  font-family: CeraPro;
}

.planCardButton {
  -ms-flex-item-align: center;
      align-self: center;
  background-color: #ffffff;
  width: 192px;
}

.productIconHeader{
  width: 271px;
  height: 60px;
  fill: #22326E;
}

.productDescription {
  font-size: 18px;
  margin: 18px 0px;
  text-align: center;
}

.learnMore {
  padding: 10px;
  font-size: 16px;
}

.scrollable::-webkit-scrollbar {
  width: 8px;
}

.scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}

.textAlignLeft {
  text-align: left;
}

.productLabel {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
}

@media only screen and (min-width: 321px) {
  .productName {
    font-size: 32px;
  }
}

@media only screen and (min-width: 960px) {
  .productIconHeader{
    width: 335px;
    height: 72px;
  }
}