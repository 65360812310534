.mainTitle {
  font-weight: bold;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 24px;
  text-align: center;
  color: #212121;
  padding: 0 16px 25px;
  border-bottom: 1px solid #e6e6e6;
  margin-top: -10px;
}

@media only screen and (min-width:768px) {
  .mainTitle {
    padding: 0 25px 25px;
  }
}
