.supportFlyoutDesktop {
  margin-top: 12px;
  width: 400px;
  z-index: 1;
}

.activeFilter {
  position: relative;

  span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ed9324;
    display: inline-block;
    top: 0;
    position: absolute;
    right: 0;
  }
}

.noClick
{
  pointer-events: none;
  span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: grey;
    display: inline-block;
    top: 0;
    position: absolute;
    right: 0;
  }
}

.ownerFilterMobile div[class='CwDropdown-dropDown'] {
  position: absolute;
  top: 40px !important;
  left: 0 !important;
  height: 100px;
}

span[class='CwPill-pill']{
  display: none;
}

input[data-cwid='dynamicmultslct_productsfilter_trigger'],
input[data-cwid='dynamicmultslct_componentsfilter_trigger'],
input[data-cwid='dynamicmultslct_statusesfilter_trigger'],
input[data-cwid='dynamicmultslct_fixversionsfilter_trigger'],
input[data-cwid='dynamicmultslct_patchversionsfilter_trigger'],
input[data-cwid="dynamicmultslct_mywatchedissuesfilter_trigger"]  {
  height: 40px;
  padding-left: 10px;
}

div[data-cwid='flycomp_knownissuesfilterflyout_flyout'] {
  z-index: 10000 !important;
}

div[class='CwDropdownMulti-dropDown'] {
  max-height: 270px;
}

.supportFlyoutContainer {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 5px;
  width: 200px;
}

.supportFlyoutContainer::before {
  right: calc(100% - 210px);
  top: -8px;
  border-top: transparent;
  border-right: transparent;
  border-bottom: inherit;
  border-left: inherit;
}

.supportFlyout {
  width: 249px;
  margin: 15px 0 0 -148px;
}

.filterActionButton {
  height: 36px;
  font-size: 14px;
}

.searchIconKnownIssue {
  padding-right: 30px;
  background: url('/images/search.svg') no-repeat 95% 10px;
  width: 292px;
  margin: 20px;
}
.filteractionPane {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-top: 7px;
}

.desktopFilterContainer {
  margin-left: auto;
}

.mobileInDesktop {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;

  .filterMobile {
    height: 48px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 8px 0;
  }

  .filterMobile > div {
    padding-left: 24px;
  }
  .helpContainer {
    position: relative;
  }

  .hideSearch {
    display: none;
  }
  .filterSearch {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .mobileSearchContainer {
    background-color: #fff;
  }
  .fixedSearchIcon {
    position: absolute;
    z-index: 1;
    left: 73px;
    top: 50%;
    margin-top: -12px;
  }
  .mobileSearch {
    border-radius: 0;
    margin: -20px 0 0;
    padding: 0 35px 0 36px;
    height: 40px;
    background-color: #fff;
    width: calc(100% - 93px);
    position: absolute;
    left: 62px;
    top: 50%;
    border: 2px solid #267E9C;
    border-radius: 4px;
  }
  .mobileSearchReset {
    position: absolute;
    right: 47px;
    top: 50%;
    height: 14px;
    width: 14px;
    margin-top: -7px;
  }
  .mobileSearchClose {
    position: absolute;
    right: 0;
    top: 50%;
    height: 18px;
    width: 17px;
    margin-top: -9px;
  }
  .fixedSearch {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    width: calc(100% - 150px);
  }

  .filterContainer {
    height: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-bottom: 10px;
  }

  .mobileSearchContainer {
    background-color: #fff;
  }

  .mobileSearch {
    border-radius: 0;
    margin: -20px 0 0;
    padding: 0 35px 0 36px;
    height: 40px;
    background-color: #fff;
    width: calc(100% - 93px);
    position: absolute;
    left: 62px;
    top: 50%;
    border: 2px solid #267E9C;
    border-radius: 4px;
  }

  .mobileSearchReset {
    position: absolute;
    right: 47px;
    top: 50%;
    height: 14px;
    width: 14px;
    margin-top: -7px;
  }
}
.filterLabel {
  font-size: 11px;
}
.helpbutton {
  display: none;
}
.helpIcon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}
.filterContent {
  width: 400px;
}
.subhelptext {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 10px;
}
.helplink {
  color: blue;
}

span[class='CwPill-loadingPill']{
  display: none !important;
}

@media only screen and (max-width: 480px) {
  .mobileInDesktop {
    .filterSearch {
      position: static;
    }

    .fixedSearch {
      width: 100%;
    }

    .fixedSearchIcon {
      left: 8px;
    }

    .mobileSearch {
      left: 0px;
      width: calc(100% - 30px);
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) {
  .fixVersionMultiselect div[class='CwDropdownMulti-dropDown'] {
    height: 180px !important;
    top: -200px !important;
  }

  .statusesMultiSelect div[class='CwDropdownMulti-dropDown'] {
    height: 180px !important;
    top: -200px !important;
  }

  .myWatchedIssuesMultiselect div[class='CwDropdownMulti-dropDown'] {
    height: 100px !important;
  }
}

@media only screen and (max-width: 768px) {
  .supportFlyoutContainer {
    grid-row-gap: unset;
    padding: 5px 16px;
  }
}

@media only screen and (max-width: 380px) { 
  .supportFlyoutContainer {
    width: 175px;
  }
}