.wrap {
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.imageIcon {
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  margin-right: 8px;
  -o-object-fit: contain;
     object-fit: contain;
}

.name {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-size: 0.75em;
}

.closeIcon {
  height: 14px;
  width: 14px;
  padding: 0px 8px;
}