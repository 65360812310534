
.breadcrumbs{
    padding: 7px 32px;
    padding-bottom:12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left:10px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.breadcrumblist{
    color: #267E9C;
    cursor: pointer;
    font-size:14px;
    &:hover{
        text-decoration: underline;
    }
}
.separator{
    color: #7d7d7d;
    margin: 0 3px;
}
.colorTextPrimary{
    color: #212121;
    font-size:14px;
}
.breadcrumbCollapser {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .ellipsisSeparator{
    color: #7d7d7d;
    margin: 0 10px;
}