.editSupportTicketDrawerContainer {
  width: 100%;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 25px;
  border-bottom: 1px solid #e6e6e6;
}

.headerArrow {
  height: 16px;
  width: 16px;
}

.headerArrowsContainer {
  width: 32px;
  height: 16px;
}

.headerArrowsContainer > div {
  display: inline-block;
}

.mainTitle {
  font-size: 2em;
  font-weight: bold;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  color: #212121;
}

.summaryAndNotes {
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .editSupportTicketDrawerContainer {
    width: 768px;
  }

  .summaryAndNotes {
    overflow: auto;
    height: calc(100% - 66px);
  }
}
