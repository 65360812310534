.animated {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fast {
  @extend .animated;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.delayOne {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
  }
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(1000px, 0, 0);
            transform: translate3d(1000px, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(1000px, 0, 0);
            transform: translate3d(1000px, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
          animation-name: fadeOutRight;
}

.open {
  border-top: 5px solid transparent;
  -webkit-animation: load-animate infinite linear 1s;
          animation: load-animate infinite linear 1s;
}

@-webkit-keyframes load-animate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: 0.35;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load-animate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: 0.35;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}