$base-font: Roboto;
$label-color: #7d7d7d;
$name-color: #4a4a4a; 

.sectionContainer {
  overflow: auto;
}

.sectionContainer::-webkit-scrollbar {
  width: 8px;
}
.sectionContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.sectionContainer::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}

.mainContainerMobile {
  padding: 24px 16px 24px;
  border-bottom: 1px solid #e6e6e6;
}

.headerContainerMobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.nameMobile {
  font-family: $base-font;
  font-weight: bold;
  color: $name-color;  
}

.name {
  @extend .nameMobile;
  margin-left: 16px;
}

.date {
  font-family: $base-font;
  font-weight: normal;
  color: $label-color;
  margin-left: 5px;
}

.dateMobile {
  font-family: $base-font;
  font-weight: normal;
  color: $label-color;
}

.nameDateContainerMobile {
  margin-left: 16px;
}

.inlineFlexDisplay {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.notesText {
  font-family: $base-font;
  font-weight: normal;
  color: $name-color;
  margin-top: 5px;
  margin-left: 48px;
  white-space: pre-wrap;
  word-break: break-word;
}

.notesTextMobile {
  font-family: $base-font;
  font-weight: normal;
  color: $name-color;
  margin-top: 19px;
  word-break: break-word;
}

.notesContainer {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;  
  padding-bottom: 25px;
  border-bottom: 1px solid #e6e6e6;
}

.isMeBackground {
  background-color: #f5f5f5;
}

.notesTopContainer {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.attachmentIcon {
  border-radius: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 4px;
  background-color: #E6E6E6;
  fill: #B0B0B0;
  width: 32px;
  height: 32px;
}

.memberImage {
  @extend .attachmentIcon;
  border: solid 2px #026ccf;
}

.attachmentIconMobile {
  border-radius: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 4px;
  background-color: #E6E6E6;
  fill: #B0B0B0;
  width: 30px;
  height: 30px;
}

.initials {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  color: #FFFFFF;
  width: 32px;
  height: 32px;
  font-size: 0.875em;
}

.externalName {
  background-color: #E6E6E6;
  color: #B0B0B0;
}

.link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
} 

@media only screen and (min-width: 768px){
  .sectionContainer {
    max-height: calc(100% - 203px);
  }
}