.loginSection {
  height: 100%;
  margin:-16px;
  overflow:hidden;
}

.imageHolder {
  position: relative;
  height: auto;
  width: 100%;
  padding: 0px;
  overflow:hidden;
  min-height: 330px;
}

.imageHolder img {
  -o-object-position: center;
     object-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  width: auto;
  height: auto;
  max-width: 100%;
}

.imageHolder .connectwiseIconWhite {
  position: absolute;
  top: 18px;
  left: 25px;
  max-width: 13%;
}

.rightPanel {
  width: 100%;
  height: 100%;
}

.panel {
  width: auto;
  margin-bottom: 0px;
  -webkit-box-flex: 3;
      -ms-flex-positive: 3;
          flex-grow: 3;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0 24px;
}

.cwIconHolder {
  margin: 40px 0 0;
  padding-left: 10px;

  img {
    height: 64px;
  }
}

.titleText {
  margin: 30px 0 20px;
  width: 400px;
  font-size: 30px;
  font-weight: 500;
}

.panelBody {
  padding: 15px;
}

.loginWrap {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #b0b0b0;
  border-radius: 4px;
  padding: 12px 10px;
  margin-top: 16px;
  cursor: pointer;
  width: 250px;

  & > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

}

.loginLogo {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.loginTitle {
  font-weight: 500;
  font-size: 0.875em;
}

.panelFooter {
  height: 18px;
  padding: 0 38px;
}

.loginFooter {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;

  .footerHelpLinks {
    font-weight: 400;
    font-size: 75%;
    color: #337ab7;
    font-family: Roboto, sans-serif;
    line-height: 1.5;
    border-radius: 3px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-decoration: none;
    padding: 2px 5px;
  }

  .copyrightMessage {
    font-size: 75%;
  }
}

@media only screen and (min-width: 768px){

  .loginSection {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0;
  }

  .imageHolder {
    max-width: 44%;
    height: 100%;
  }

  .imageHolder img {
    height: 100%;
    max-width: 92%;
  }

  .cwIconHolder {
    display: block;
  }

  .titleText {
    margin: 20px 0 10px;
  }

  .loginWrap {
    max-width: 400px;
  }

}

@media only screen and (min-width: 0) and (max-width: 480px) {
  .imageHolder {
    min-height: auto;
  }
}
