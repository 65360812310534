.label {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: absolute;
    bottom: -22px;
    right: 0px;
  }
  
  .label:hover,
  .label:focus {
    text-decoration: unset;
    color: #0067b1;
  }
  
  .textAreaLabel {
    bottom: -18px;
  }