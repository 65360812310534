.mfaContainer {
  margin-top: 15px;
}

.qrMfa {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
  margin-top: 24px;
}

.mfaToken {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  color: #7d7d7d;
  margin-left:20px;
  margin-top: 16px;
}

.setupButtonContainer {
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin-top: 37px;
}

.setupButtonContainerMobile {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.cancelButton {
  margin-right: 24px;
}

.buttonContainer {
  margin-top: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.buttonContainer:last-child {
  margin-left: 20px;
}

.mfaAuthAppHeader {
  font-size: 14px;
  font-weight: bold;
  line-height: 2.75;
  color: #212121;
}

.mfaAppButton {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 50%;
          flex: 0 1 50%;
  height: 44px;
}

.setupButtonContainer .mfaAppButton {
  width: 141px;
}

.textfield {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 15px;
  border: solid 1px #b0b0b0;
  background-color: #ffffff;
}

.mfaAppEnable {
  position: relative;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 24px;

  div[class~='CwFlyout-flyout'] {
    width: 177px;
    font-size: 16px;
    color: #373737;
    padding: 0;

    Label {
      cursor: pointer;
      padding: 12px 16px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      width: 100%;

      &:hover {
        background-color: #e3f1fc;
      }
    }
  }
}

.mfaAppTurnOff {
  margin-left: -509px;
  width: 550px;
}

.authAppContainerTurnOff{
  margin-top: -153px;
  width: 85%
}

.mobileContainer {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.keyOpacity {
  opacity: 0.2;
}

.qrCode {
  -ms-flex-item-align: center;
      align-self: center;
  margin-bottom: 14px;
}

.menuItem {
  padding: 12px 16px;
  font-size: 0.875em;
  color: #373737;
  cursor: pointer;
}

.menuItem:hover {
  background-color: #e3f1fc;
}

.CwImage {
  position: relative;
}

.copyKey {
  cursor: pointer;
  position: relative;
  right: -358px;
  top: -34px;
}

.mfaWarning {
  background-color: #ffebdd;
  padding: 18px 32px;
  position: relative;
  margin-top: 40px;
  width: 470px;
  margin-left: 28px;

  .warningIcon {
    width: 18px;
    position: absolute;
    top: 15px;
    left: 8px;
    fill: #ed9324;
  }

  .warningCancelIcon {
    position: absolute;
    right: 8px;
    top: 3px;
    fill: #212121;
    width: 16px;
    cursor: pointer;
  }

  .warningIconRed {
    fill: #ca001a;
  }

  .warningIconBlue {
    fill: #026ccf;
  }

  .mfaWarningText {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.authMfaTurnOff {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .mfaButton {
    height: 24px;
    margin-left: 25px;
    color: #ed9324;

    &.mfaButtonRed {
      color: #ca001a;
    }
  }
}

.authAppContainer{
  width: 85%;
}

.mfalabel {
  width: 345px;
  height: 22px;
  color: #212121;
  padding-left: 17px;
}

.turned {
  color: #7d7d7d;
  font-size: 12px;
}

.subHeaderDescription {
  margin-bottom: 24px;
}

.emailText {
  color: #212121;
}

.label {
  font-size: 14px;
}

.email {
  color: #b0b0b0;
  height: 40px;
  margin-left: 20px;
}

.emailVerifyBold {
  font-weight: bold;
  margin-top: 10px;
}

.secretkeyConatiner {
  font-weight: bold;
  margin-top: 16px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.textfieldMfaCode {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #b0b0b0;
  background-color: #ffffff;
  margin-right: 130px;
  margin-left: 20px;
}

.verifyErrorContainer {
  position: relative;
}

.errorStyle {
  color: #ca001a;
  text-align: center;
  position: absolute;
  bottom: -22px;
  padding-left: 10px;
}

.verifyCode {
  margin-top: 8.5px;
}

.mfaStatusText {
  margin-left: 5px;
}

.mfaDisabled {
  fill: #ed9324;
}

.authSetUpWarning {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  background-color: #ffebdd;
}

.warningIconAuth {
  fill: #ed9324;
  margin-top: 15px;
}

.authTextWarning {
  padding: 16px 33px 22px 3px;
  font-size: 14px;
  color: #212121;
}

.authAppActive {
  margin-right: 148px;
}

.authAppActiveTurnOff {
  margin-right: -167px;
}

@media only screen and (min-width: 768px) {
  .mfaAuthAppHeader {
    font-size: 16px;
  }

  .textfield {
    width: 90%;
  }

  .authAppContainer{
    width: 100%;
  }
}
