.loadingComponent {
    width:100%;
}

.loader {
    height: 24px;
    width: 100%;
    margin-bottom: 10px;
}

.titlePlaceholder {
    padding: 10px 38px 75px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-bottom: 1px solid #e6e6e6;
}

.titleLoader {
    width: 70%;
}

.contentPlaceholder {
    padding: 34px 38px;
}

.halfWidthLoader {
    width: 181px;
}

.quantityLoader {
    margin-top: 30px;
}

.smallWidthLoader {
    width: 134px;
}

.lastLoader {
    width: 210px;
}

.licenseQuantityDropDown{
    width: 134px;
    height: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-bottom: 32px;
    border-radius: 4px;
    border: solid 1px #b0b0b0;
}

.licenseQuantityDropDown:disabled{
    background-color: #fff;
}

@media only screen and (min-width: 767px) {
    .loadingComponent {
        width:480px;
    }

    .titlePlaceholder {
        padding: 10px 64px 55px;
    }

    .contentPlaceholder {
        padding: 34px 64px;
    }

    .lastLoader {
        width: 288px;
    }
}