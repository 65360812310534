.container {
  background-color: #ffffff;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: calc(100% - 48px);
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  align-items: center;
  overflow: auto;
}

.partnerSuccessManagerDrawer {
  width: 100%;
  height: 100%;
  position: relative;
}

.managerHeaderDrawerContainer{
  height:100px
}

.header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 20px 20px 20px;   
}

.nameLabelContainer{
  height: 72px;
}

.name { 
  width: 180px;
  color: #212121;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  margin-top:5px;
}

.productDetails {
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  color: #212121;
  margin-top: 10px;
}

.productContainer {
  -webkit-box-shadow: 0 2px 12px 0 rgba(40, 50, 66, 0.15);
          box-shadow: 0 2px 12px 0 rgba(40, 50, 66, 0.15);
  border-radius: 4px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  cursor: pointer;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 32px 32px;
  border-radius: 8px;
  margin: 20px 0;
}

.productContainer:hover {
  -webkit-box-shadow: 0 2px 4px 3px rgba(33, 33, 33, 0.2);
          box-shadow: 0 2px 4px 3px rgba(33, 33, 33, 0.2);
  .mediumLabel {
    color: #026ccf;
  }
}

.mainCategoryBox {
  width: 248px;
  height: 100px;
  border-radius: 4px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 15px 24px 0;
  cursor: pointer;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  -webkit-box-shadow: 0 2px 12px 0 rgba(40, 50, 66, 0.15);
          box-shadow: 0 2px 12px 0 rgba(40, 50, 66, 0.15);
  padding: 0 24px;
}

.mainCategoryBox:hover {
  -webkit-box-shadow: 0 2px 4px 3px rgba(33, 33, 33, 0.2);
          box-shadow: 0 2px 4px 3px rgba(33, 33, 33, 0.2);
  .categoryItems {
    color: #026ccf;
  }
}

.mediumLabel {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #212121;
  cursor: pointer;
}

.footerContainer {
  background-color: #ffffff;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  height: 72px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  bottom: 50px;
  text-align: center;
}

.nameContainer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 10px;

}

.createTicketLink {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding: 12px 0;
  height: 48px;
  &:hover{
    opacity: 0.9;
    color: #267E9C;
  }
}

.detailsContainer {
  width: 100%;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  margin-bottom: 32px;
}

.textfieldFormContainer,
.tellUsMorePage {
  width: 100%;
}

.tellUsMoreLabel {
  font-size: 24px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  font-weight: bold;
  padding-bottom: 32px;
}

.content {
  background-color: #fafafa;
  height: 100%;
}

.columnData {
  width: 100%;
  padding: 0 20px 0 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.scrollableArea {
  width: 100%;
  overflow: auto;
  background-color: #fafafa;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-top: 1px solid #e6e6e6;
}

.scrollableArea.noBreadcrumSpace {
  padding-top: 30px;
}

.scrollableArea::-webkit-scrollbar {
  width: 8px;
}

.scrollableArea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollableArea::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}

.categoryItems {
  font-weight: bold;
  color: #212121;
  cursor: pointer;
  text-align: center;
}

.categoryItems:hover {
  color: #026ccf;
}

.mainCategoryLoading {
  height: 20px;
  width: 120px;
}

.buttonContainer {
  padding: 12px 0;
  background-color: #fafafa;
  height: 48px;
  width: 100%;
}

.submitButtonContainer {
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 48px;
  padding: 12px 0;
  border-top: 1px solid #e6e6e6;
}

.titleName {
  font-size: 24px;
  font-weight: bold;
  color: #212121;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 400px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.viewTicket {
  margin-bottom: 40px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.profileImage {
  width: 64px;
  height: 64px;
  border: solid 2px #ffffff;
  border-radius: 50%;
  margin-right:8.5px;
  padding: 8px 8px;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.profileImage img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
}
  
.profileImage div {
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
}

.myTeamHeaderDescription {
  color: #4A4A4A;
  font-size: 14px;  
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.successManagerLabel {
  height: 18px;
  width: 252px;
  color: #4A4A4A;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
}

.myTeamHeaderTitle {
  font-size: 18px;
  font-weight: bold;
  color: #212121;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: center;
}

.emailWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
}

.contactMethod {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  -ms-flex-item-align: left;
      align-self: left;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.stepHeading {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 0 0 4px 0;
  margin-bottom: 4px;
}

.stepDescription {
  color: #4A4A4A;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.labelContainerBreadCrumbs {
  padding-bottom: 18px;
}

.drawerItem {
  -webkit-box-shadow: 0 2px 12px 0 rgba(40, 50, 66, 0.15);
          box-shadow: 0 2px 12px 0 rgba(40, 50, 66, 0.15);
  border-radius: 4px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  cursor: pointer;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0 20px 0;
  min-width: 49%;
  -ms-flex-direction: row;
      flex-direction: row;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}

.drawerItem:hover {
  -webkit-box-shadow: 0 2px 4px 3px rgba(33, 33, 33, 0.2);
          box-shadow: 0 2px 4px 3px rgba(33, 33, 33, 0.2);
}

.drawerItemIconFlex {
  padding: 12px;
  border: solid 0px #ffffff;
  border-radius: 50%;      
  -webkit-box-pack: center;      
      -ms-flex-pack: center;      
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background-color: #CCC;
  fill: #212121;
}

.drawerItemResourceInfo {
  max-width: 80%;
  padding: 0 0;
  position: relative;
  width: 80%;
  padding: 5px 15px;  
}

.drawerItemTitleLabel {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  color: #212121;
  cursor: pointer;
  height: 24px;
}

.drawerItemInfoIcon {
  fill: #267E9C;
  max-width: 12px;
  line-height: 11px;
  height: 14px;
}

.drawerItemInfoText {
  color: #267E9C;
  padding: 1px 0px 0px 3px;
  font-size: 10px;
  line-height: 11px;
  cursor: pointer;
  height: 14px;
  letter-spacing: 0;
} 

.drawerItemFlyoutContent {
  width: 100%;
  min-width: 200px;  
  line-height: 18px;
  font-size: 14px;
}

.drawerItemFlyoutTitle {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
  margin-bottom: 6px;
}

.drawerSplitter {
  border-top: 1px solid #d8d8d8;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .partnerSuccessManagerDrawer {
    width: 600px;
  }

  .profileImage img {
     min-width: 64px;
  }

  .nameContainer {
    width: 330px
  }

  .container {
    width: 600px;
  }

  .header {
    padding: 0 40px 20px 40px;
  }

  .name {
    font-size: 24px;
  }

  .myTeamHeaderTitle {
    font-size: 24px;
    width: 345px;
  }
}

@media only screen and (max-width: 475px) {
  .titleName {
    font-size: 18px;
    width: 219px;
  }
  
  .profileImage {
    width: 40px;
    height: 40px;
    margin-right: 0px;
    padding-right: 0;
  }

  .name {
    padding-left: 8px;
  }

  .successManagerLabel {
    padding-left: 8px;
  }

  .mediumLabel {
    font-size: 14px;
  }

  .productContainer {
    padding: 16px 16px;
  }

  .mainCategoryBox{
    width: 330px;
    height: 64px;
    margin: 0 0 16px 0;
    padding: 24px 20px 20px 20px;
  }

  .columnData{
    padding: 0 16px 0 16px;
  }

  .labelContainerBreadCrumbs{
    padding-bottom: 28px;
  }

  .productContainer{
    margin: 0 0 25px 0;
  }
}
