.previewContainer {
  padding: 20px;
  border: solid 1px grey;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: auto;

  & > * {
    width:100%
  }
}

.mainTitle {
  font-weight: 500;
  font-size: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: -16px;
  margin-bottom: 40px;
}

.container {
  width: 600px;
  background-color: #ffffff;
  overflow-y: auto;
  padding: 0 25px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.title {
  font-weight: 500;
  font-size: 18px;
}

.tag {
  font-size: 11px;
  margin-top: 10px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  word-break: break-all;
}

.previewLoading{
  height: 200px;
  width: 550px;
  margin: 0 25px;
}
