.licenseActions {
  margin: 20px 0 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  .normalActions {
    .btnBack,
    .btnConfirm,
    .loadingBtnContinue {
      height: 42px;
      width: 120px;
    }

    .loadingBtnContinue {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
    }

    .btnBack {
      margin-right: 15px;
    }
  }
}

.licenseActions.licenseQuote {
  .errorActions {
    .licenseQuoteBtn,
    .loadingBtnQuote {
      width: 199px;
      height: 42px;
    }

    .loadingBtnQuote {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;;
    }

  }
}
.licenseActions.contactManagerBtn {
  .errorActions {
    .licenseQuoteBtn,
    .contactManagerAction {
      width: 267px;
      font-size: 15px;
      height: 38px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
    }

    .contactManagerAction {
      background-color: #026ccf;
      color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      text-decoration: none;
      border-radius: 4px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .licenseActions.licenseQuote,
  .licenseActions.contactManagerBtn {
    .errorActions {
      .licenseQuoteBtn,
      .contactManagerAction {
        width: 292px;
        font-size: 16px;
      }

      .loadingBtnQuote {
        width: 292px;
      }
    }
  }
}
