.container {
  width: 459px;
  overflow: auto;
  padding: 35px 40px;
}

.password {
  margin-top: 16px;
  border: 1px solid #e6e6e6;
  padding: 8px;
  width: 100%;
}

.copyImg {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  cursor: pointer;
  right: 5px;
  top: 23px;
  position: absolute;
  fill: #b0b0b0;
}

.headertext {
  margin-top: 20px;
}

.description {
  width: 100%;
  position: relative;
}
.closeButton {
  width: 112px;
  height: 32px;
  font-size: 14px;
}
