.container {
  width: 100%;
  height: calc(100% - 48px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: auto;
}
.retryPaymentTitle {
  margin-bottom: 60px;
  font-size: 24px;
  color: #2e2e2e;
  text-align: center;
  margin: 0 0 40px 0;
}
.messageContent {
  margin-bottom: 20px;
  font: 18px;
  color: #252525;
  text-align: center;
  width: 330px;
  margin: 0 auto 70px;
  max-width: 80%;
}

.image {
  height: 300px;
  width: 100%;
  padding: 0px 20px;
}

.btn {
  margin-top: auto;
  width: 88%;
  margin-bottom: 40px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

@media only screen and (min-width: 520px) {
  .image {
    width: 412px;
  }
}


@media only screen and (min-width: 768px) {
  .container {
    height: calc(100% - 48px);
  }

  .btn {
    width: 137px;  
  }
}
