.wrap {
  width: 446px;
  padding-top: 24px;
}

.noButton,
.yesButton {
  width: 110px;
  font-size: 14px;
}
