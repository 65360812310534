.dialog {
  padding: 0px;
}

.wrap {
  padding: 16px;
}

.bannerWelcome {
  font-size: 1em;
  color: #ffffff;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 768px;
  height: 470px;
  background: #ffffff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.bannerImage {
  height: 250px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  max-height: 50%;
}

.innerContent {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: -200px;
  overflow: auto;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  z-index: 9999;
}

.header {
  word-break: break-word;
  display: block;
  font-size: 1.3em;
  font-weight: bold;
  margin-top: 10px;
  color: #ffffff;
}

.subHeader {
  word-break: break-word;
  display: block;
  font-weight: bold;
  font-size: 1em;
  margin-top: 8.5px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 16px;
}

.createBtn {
  margin-top: 2px;
  height: 40px;
  border-color: transparent;
  padding: 0px 16px !important;
}

.createBtn > div {
  background: transparent;
}

.createBtn:focus > div,
.createBtn:hover > div {
  background: transparent !important;
}

.successManagerBanner {
  border: solid 2px #ffffff;
  background-color: rgba(33, 33, 33, 0.4);
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 10px 0 10px 16px;
  color: #ffffff;
  height: 28px;
}

.successManagerBanner:hover {
  background: rgba(50, 104, 138, 0.5);
  border: solid 2px #ffffff;
}

.innerFlex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.bannerButtonWrapper {
  margin: 2px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 60%;
}
