.productDescription {
  padding:0 20px;
  margin:29px 0 16px 0;
}

.productIconHeader {
  height: 60px;
  width: 271px;
  fill: #22326E;
}

.container {
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.scrollable {
  height: calc(100% - 49px);
  overflow-y: auto;
  overflow-x: hidden;
}

.banner {
  color:#FFFFFF;
}

.bannerHeaderText{
  font-size: 24px;
  margin-bottom: 16px;
}

.bannerDescription {
  margin-bottom: 32px;
}

.benefit {
  padding:24px;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 37%;
          flex: 0 1 37%;
}

.benefitImage {
  margin-right: 8px;
}

.benefitHeader {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  padding: 0 38px;
  margin: 32px 16px 16px;
}

.benefitMessage {
  text-align: left;
}

.textBold {
  font-weight: bold;
}

.connectPlan {
  color: #7c5ea3;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 81px;
}

.ownedProductText {
  margin-top: 49px;
}

.planContainer {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.plan {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24%;
          flex: 0 0 24%;
}

.buttonContainer {
  width: 360px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px;
}

.bannerHeader{
  background-image: url('/images/drawer/sell_drawer.jpg');
  height:660px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-repeat: round;
  padding: 56px 16px;
}

.planContainer>div {
  margin-bottom: 16px;
}

.planCardButton {
  position: relative;
  margin-bottom: 0;
  border-color: #7c5ea3;
  color: #7c5ea3;  
}

.planCardButton:hover {
  background-color: #7c5ea3;
  border-color:  #7c5ea3;
  color:#ffffff;
}

.dividerLineHeader {
  margin-bottom: 34px;
}

.learnMoreClassName {
  color: #7c5ea3;
}

@media only screen and (min-width: 768px) {
  .banner {
    padding: 0 20px;
  }

  .bannerHeaderText {
    padding: 0 60px;
  }

  .ownedProductText {
    margin-top: 36px;
  }

  .connectPlan {
    margin-bottom: 81px;
  }
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 920px;
  }

  .planContainer>div:first-child {
    margin-right: 16px;
  }

  .productName {
    font-size: 32px;
  }

  .dividerLine {
    margin-bottom: 32px;
  }

  .bannerHeader{
    background-image: url('/images/drawer/sell_drawer@2x.jpg');
    height: 401px;
    padding: 74px 16px 73px;
  }

  .productIconHeader {
    height: 72px;
    width: 335px;
  }

  .benefits {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  
  .benefitTitle {
    font-size: 18px;
  }
}
