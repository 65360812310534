.inactivity {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  width: 400px;
  border-radius: 8px;
}

.label {
  margin-bottom: 10px;
  font-size: 15px;
}

.boldLabel {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}
@-webkit-keyframes blink {
  50% {
    opacity: 0;
  }
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
.timer {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: bolder;
}

.stopwatch {
  background: url('/images/stopwatch.svg') no-repeat;
  background-size: 20px 20px;
  padding-left: 25px;
}

.time {
  -webkit-animation: blink 1s linear infinite;
          animation: blink 1s linear infinite;
}

.buttonContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logoutButton {
  background-color: white;
  color: rgb(54, 72, 179);
  font-size: 15px;
}

.stayLoggedInButton {
  background-color: rgb(54, 72, 179);
  color: white;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(146, 141, 194, 0.5);
  z-index: 1000;
}
