.productName {
  font-size: 24px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.productDescription {
  padding:0 20px;
  margin: 18px 0;
}

.productIconHeader {
  width: 271px;
  height: 60px;
  fill: #22326E;
}

.container {
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.scrollable {
  height: calc(100% - 49px);
  overflow-y: auto;
  overflow-x: hidden;
}

.banner {
  color:#FFFFFF;
}

.bannerHeaderText{
  font-size: 24px;
  margin-bottom: 16px;
}

.bannerDescription {
  margin-bottom: 32px;
}

.benefits {
  margin-bottom: 64px;
}

.benefit {
  padding:24px;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 37%;
          flex: 0 1 37%;
}

.benefitImage {
  width: 48px;
  height: 48px;
  margin-right: 8px;
}

.benefitHeader {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  padding: 0 38px;
  margin: 32px 16px 16px;
}

.benefitMessage {
  text-align: left;
}

.textBold {
  font-weight: bold;
}

.connectPlan {
  padding: 10px;
  font-size: 12px;
}

.planContainer {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.plan {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24%;
          flex: 0 0 24%;
}

.buttonContainer {
  width: 360px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px;
}

.bannerHeader{
  background-image: url('/images/drawer/cloud-console.jpg');
  height: 527px;
  background-repeat: round;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.planContainer>div {
  margin-bottom: 16px;
}

.planCardButton {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24%;
          flex: 0 0 24%;
  position: relative;
  margin-bottom: 0;
  border-color: #54beed;
  color: #54beed;  
  background-color: #ffffff;
}

.planCardButton:hover {
  color:#ffffff;
  background-color: #54beed;
  border-color: #099fe3;
}

.dividerLineHeader {
  margin-bottom: 34px;
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 920px;
  }

  .planContainer>div:first-child {
    margin-right: 16px;
  }

  .productName {
    font-size: 32px;
  }

  .dividerLine {
    margin-bottom: 32px;
  }

  .bannerHeader{
    background-image: url('/images/drawer/cloud-console@2x.jpg');
    height: 401px;
    background-repeat: round;
  }

  .productIconHeader {
    width: 335px;
    height: 72px;
  }

  .benefits {
    margin-bottom: 124px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .benefitImage {
    width: 88px;
    height: 88px;
    margin-right: 8px;
  }
  
  .benefitTitle {
    font-size: 18px;
  }
}
