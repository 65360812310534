.attribute {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 20px;
}

.label {
  margin: 0 5px 0 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.attributeLabel {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.input {
  margin: 12px 0px;
}

.standardInput {
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  font-size: 1em;
  background-color: transparent;
  text-overflow: ellipsis;
  width: 100%;
  padding: 13px 16px;
  color: #4A4A4A;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.value {
  font-size: 16px;
  font-weight: lighter;
  font-style: normal;
  line-height: normal;
  color: #4A4A4A;
}

.email{
  height: 40px;
  background-color: rgba(245, 245, 245, 0.5);
}

.combobox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 130px;
  height: 40px;
  background-color: #ffffff;
}

// The bounding rectangle is being computed incorrectly since we are using a popper.
/*.combobox div[class="CwDropdown-dropDown"] {
  top: 68px !important;
  left: 151px !important;
}*/

.combobox > div > input[class="CwTextField-textField"] {
  padding-top: 9px;
  padding-bottom: 9px;
}

.textField {
  max-width: 292px;
  min-width: 125px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #b0b0b0;
  background-color: #ffffff;
}