.container {
  text-align: center;
  width: calc(100% - 40px);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 20px;
}

.scrollable {
  height: calc(100% - 49px);
  overflow-y: auto;
  overflow-x: hidden;
}

.plan {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-preferred-size: 348px;
      flex-basis: 348px;
}

.planCard {
  min-width: 208px;
  height: 228px;
  margin-top: 12px;
  border-radius: 10px 10px 0px 0px;
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  border-color: #e6e6e6;
  text-align: left;
  padding: 30px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
  background-image: url('/images/HomePage/unite-basic@3x.png');
  color: #ffffff;
}

.buttonContainer {
  width: 360px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px;
}

.requestHelp {
  padding: 12px;
  font-weight: 500;
}

.featureCheckIcon {
  width: 17px;
  height: 16px;
  fill: #026ccf;
}

.planCardLabel {
  padding: 4px 0px;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.planCardButton {
  margin-top: auto;
}

.planCardName {
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.planCardPrice {
  font-size: 36px;
}

.planCardPriceInterval {
  font-size: 18px;
}

.planCardStatus {
  height: 20px;
  padding: 0px 8px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.7;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
  background-color: #549c05;
}

.planCardStatusGray {
  color: #000000;
  background-color: #e6e6e6;
}

.categoryCard {
  max-width: 380px;
  height: 288px;
  border-radius: 0px 0px 10px 10px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  margin-bottom: 12px;
  text-align: left;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
}

.categoryContainer {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-style: solid;
  border-color: #e6e6e6;
  width: calc(100% - 60px);
  border-width: 0px;
  padding: 0px 30px;
}

.categoryDisabled {
  color: #dbdbdb;
}

.categoryContainer:not(:nth-child(1)) {
  border-top-width: 1px;
}

.category {
  font-size: 18px;
  font-weight: bold;
}

.categoryDescription {
  font-size: 14px;
}

.categoryComment {
  font-size: 12px;
}

.productStatusRed {
  color: #ffffff;
  background-color: #ca001a;
}

.productStatusGreen {
  color: #ffffff;
  background-color: #008000;
}

.productIconHeader {
  width: 271px;
  height: 60px;
  fill: #22326E;
}

.productDescription {
  font-size: 18px;
  margin: 18px 0px;
  text-align: center;
}

.dividerLineHeader {
  width: calc(100% + 200px);
  margin: 20px 0px;
  border-bottom: 1px solid #e6e6e6;
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 920px;
  }

  .planContainer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .plan {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    margin: 12px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .planCard {
    min-width: 288px;
    -ms-flex-preferred-size: 318px;
        flex-basis: 318px;
    border-radius: 10px 0px 0px 10px;
    border-width: 1px 0px 1px 1px;
    border-color: #e6e6e6;
    margin: 12px 0px;
  }

  .categoryCard {
    min-width: 368px;
    height: 288px;
    border-radius: 0px 10px 10px 0px;
    margin: 12px 0px;
  }

  .productIconHeader {
    width: 335px;
    height: 72px;
  }
}