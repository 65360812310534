.bannerDrawer {
    width: 1000px;
    height: 100%;
    position: relative;
  }
  
  .typeDetails {
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }
  
  .largeLabel {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .smallLabel {
    font-size: 14px;
    text-align: center;
  }
  
  .details {
    background-color: #fafafa;
    padding: 0 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  .labelContainer {
    padding-bottom: 20px;
  }
  
  .productContainer {
    -webkit-box-shadow: 0 2px 12px 0 rgba(40, 50, 66, 0.15);
            box-shadow: 0 2px 12px 0 rgba(40, 50, 66, 0.15);
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    cursor: pointer;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  
  .productContainer:hover {
    -webkit-box-shadow: 0 2px 4px 3px rgba(33, 33, 33, 0.2);
            box-shadow: 0 2px 4px 3px rgba(33, 33, 33, 0.2);;
    color: #267E9C;
  }
  
  .footerContainer {
    width: 1000px;
    height: 72px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: fixed;
    text-align: center;
    padding: 11px 0;
  }
  
  .scrollableArea {
    width: 100%;
    overflow: auto;
    overflow-y: auto;
    background-color: #fafafa;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-bottom: 35px;
  }
  
  .scrollableArea.noBreadcrumSpace {
    padding-top: 30px;
  }
  
  .scrollableArea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  
  .scrollableArea::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
  
  .detailsAndPreviewContainer{
    & > * {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 990px;
              flex: 0 0 990px;  
    }
  }
  
  .bannerDetails {
    width: 100%;
  }
  
  .textfield {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #b0b0b0;
    background-color: #ffffff;
  }
  
  .textArea {
    width: 100%;
    height: 80px !important;
    border-radius: 4px;
    border: solid 1px #cccccc;
    background-color: #ffffff;
  }
  
  .container {
    background-color: #ffffff;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: calc(100% - 48px);
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    align-items: center;
    width:600px
  }
  
  .dropDown {
    background-color: #ffffff;
  }
  
  .labels {
    display: block;
      font-size: 0.875em;
      font-weight: 500;
      color: #7D7D7D;
    padding: 24px 0 4px 0;
  }
  
  .previewContainer {
    padding: 20px;
    border: solid 1px grey;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: absolute;
    left: 600px;
    right: 20px;
    overflow: auto;
  
    & > * {
      width:100%
    }
  }
  
  .successContainer {
    height: calc(100% - 48px);
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 600px;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    width: 400px;
    text-align: center;
  }
  
  .successImage {
    width: 410px;
    height:auto;
  }
  
  .viewButton {
    margin-bottom: 15px;
  }
  
  .linkBanner {
    width: 80%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 7px 14px;
  
    label {
      font-size: 14px;
      color: #000;
      line-height: 21px;
      white-space: nowrap;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .copyLink {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    cursor: pointer;
    height: 21px;
    width: 17px;
  }
  
  .formField {
    margin: 24px 0;
    vertical-align: top;
    min-width: 300px;
  }
  
  .logoField {
    margin-top: 24px;
  }
  
  .displayAsRequired {
    ::after {
      color: #e84337;
    }
  }

  .labels:after {
    content: ' *';
    color: #e84337;
  }
  
  .colorPreview {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    -o-object-fit: contain;
       object-fit: contain;
    forced-color-adjust: none;
  }
  
  .bannerImageAttachments {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  
    & > * {
      -ms-flex-preferred-size: 48%;
          flex-basis: 48%;
      margin-bottom: 0;
    }
  }
  
  div[data-cwid='toggle_togglectabutton'] {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 24px;
  }
  
  .bannerCTAButtonToggle {
  
    div[class~="CwToggle-toggle"] {
      width: 48px;
      height: 24px;
  
      &:after {
        width: 16px;
        height: 16px;
        top: 2px;
      }
    }
  
    div[class~="CwToggle-toggleUnchecked"]:after {
      left: 2px;
    }
  
    div[class~="CwToggle-toggleChecked"]:after {
      left: 58%;
    }
  
    Label {
      color: #212121;
    }
  }
  
  .toolTipContainer {
    width: 180px;
    padding: 2px;
  }
  
  .toolTipIcon {
    height: 18px;
    width: 14px;
    fill: #267E9C;
    margin-top: 24px;
    margin-left: 4px;
  }
  
  .aboutMeTitle {
    color: #212121;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: left;
    white-space: pre-line;
    margin:8px 8px 8px 8px;
  }
  
  .btnPreview {
    padding: 4px 32px;
    margin-left: auto;
    margin-right: 20px;
  }
  
  .cancelButton {
    width: 118px;
    margin-right: 16px;
  }

  .position {
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .bannerImageFlyout {
    width: 260px;
    margin-top: 8px;
    padding: 8px;
  }