.spaceBetween {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.one {
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.justifyEnd {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.alignEnd {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.alignCenter {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}