.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 600px;
  background-color: #ffffff;
}

.content {
  overflow-y: auto;
  height: 100%;
}

.content::-webkit-scrollbar {
  width: 8px;
}

.content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.content::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}

.mobileContainer {
  width: 100%;
}

.avatar {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  color: #ffffff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 17px 0 24px;
}

.sectionTitle {
  font-size: 1.1em;
  font-weight: bold;
  margin-top: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.inputGroup {
  margin-bottom: 20px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;

  Label {
    line-height: 1.71;
  }
}

.namesInput{
  -webkit-box-flex: 0;
      -ms-flex: 0 1 256px;
          flex: 0 1 256px;

  input {
    height: 40px;
    border: 1px solid #b0b0b0;
  }
}

.area {
  margin-bottom: 38px;
}

.close {
  width: 25px;
  height: 25px;
}

.link {
  line-height: 1.5;
}

.login {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top:20px;
}

.emailRequired {
  color: red;
}

.footer {
  padding: 25px;
}

.checkboxCloseAccount {
  margin-top: 20px;
}

.buttonContainer {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.profileButton {
  width:  115px;
}

.partnerId {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.copyImg {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  cursor: pointer;
  height: 21px;
  width: 17px;
}

.invalidInputHide {
  display: none;
}

.invalidInputShow {
  position: absolute;
  right: 10px;
  top: 32px;
}

.invalidInput {
  fill: #e74c3c !important;
  background: #e74c3c url(/images/Tile_Alerts.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 338px 15px;
}

.message {
  width: 50%;
  word-break: break-word;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.messageValid {
  color: #212121;
  width: 50%;
  word-break: break-word;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.messageInvalid {
  color: #212121;
  width: 50%;
  word-break: break-word;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.validationImage {
  float: left;
  width: 12px;
  height: 12px;
  margin-top: 4px;
  margin-right: 10px;
}

.validationImageInvalid {
  fill: #e74c3c !important;
}

.validationImageValid {
  fill: #2ecc71 !important;
}

.validationMessage {
  float: left;
  width: 50%;
  white-space: nowrap;
}

.inputRequirements {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  max-height: 0;
  color: rgb(200, 200, 200);
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

input:focus ~ .inputRequirements,
input:active ~ .inputRequirements {
  width: 100%;
  overflow: visible;
  max-height: 200px;
  color: rgb(200, 200, 200);
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.inputConfirmRequirements {
  float: left;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  max-height: 0;
  color: rgb(200, 200, 200);
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

input:focus ~ .inputConfirmRequirements,
input:active ~ .inputConfirmRequirements {
  width: 100%;
  overflow: visible;
  max-height: 200px;
  color: rgb(200, 200, 200);
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.inputCloseAccountRequirements {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  max-height: 0;
  color: rgb(200, 200, 200);
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

input:focus ~ .inputCloseAccountRequirements,
input:active ~ .inputCloseAccountRequirements {
  width: 100%;
  overflow: visible;
  max-height: 200px;
  color: rgb(200, 200, 200);
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.closeAccountText{
  margin:30px 0px 80px ;
}

.label{
  font-size: 14px;
}

.attribute {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 32px;
}

.attribute label {

  &:after{
    content: ' *';
    color: rgb(255, 86, 40);
    position: relative;
  }
}

input{
  line-height: 1.5;
}

.email{
  color: #b0b0b0; 
  border: none;
  height: 40px;
  background-color: rgba(245, 245, 245, 0.5);
}

.closeAccountContainer{
  display: block;
  margin-top:20px;
}

.titleName {
  font-size: 24px;
  font-weight: bold;
  color: #7d7d7d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.imageContainer {
  width: 350px;
  height: 365px;
}

.image {
  width: 100%;
}

.notAccessContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow-y: auto;
  height: 100%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  background-color: #f5f5f5;
  padding: 0px 40px;
}

.billingNotificationToggle {

  div[class~="CwToggle-toggle"] {
    width: 48px;
    height: 24px;

    &:after {
      width: 16px;
      height: 16px;
      top: 2px;
    }
  }

  div[class~="CwToggle-toggleUnchecked"]:after {
    left: 2px;
  }

  div[class~="CwToggle-toggleChecked"]:after {
    left: 58%;
  }

  Label {
    color: #212121;
  }
}

.securityNotificationToggle {
  @extend .billingNotificationToggle;
  padding-top: 10px;
}

.detailAttribute {
  margin-bottom: 42px;

  .detailValue {
    font-size: 14px;
    color: #212121;
  }
}

.allPasswordContainer {
  margin-top: 30px;
}

.passwordContainer{
  margin-bottom:24px;
  position: relative;

  .passwordLabel {
    color: #7d7d7d;
    margin-bottom: 5px;
    font: 14px;
  }

  .passwordInput {
    height: 40px;
    border: 1px solid #b0b0b0;
  }
}

.closeAccountConfirmation {
  margin-top: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}