$base-font: Roboto;
$label-color: #7d7d7d;
$bottom-border: 1px solid #e6e6e6;

.mainContainer {
  padding-bottom: 25px;
  border-bottom: $bottom-border;
}

.mainContainerMobile {
  padding-bottom: 16px;
  border-bottom: $bottom-border;
  overflow:hidden;
}

.summaryContainer {
  width: 100%;
  height: 100%;
  padding-bottom: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.summaryContent {
  margin: 24px 27px 0px;
}

.summaryContentMobile { 
  margin-left: 16px;
  margin-top: 24px;
}

.columnContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.columnContainerMobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 10px;
}

.firstColumn {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.secondColumn {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.secondColumnMobile {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.sectionSummary {
  font-size: 1.5em;
  color: #212121;
  font-weight: bold;
  line-height: 1.13;
}

.label {
  font-family: $base-font;
  font-weight: 400;
  color: $label-color;
  font-size: 1em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.fieldValue {
  font-family: $base-font;
  font-weight: 400;
  color: #212121;
  padding-left: 4px;
  font-size: 1em;
}

.editIcon {
  height: 16px;
  width: 16px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 6px;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.editIconDisabled {
  @extend .editIcon;
  cursor: not-allowed;
  fill: #e6e6e6;
}

.labelValueFieldContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.bottomLabelValueFieldContainer {
  @extend .labelValueFieldContainer;
  margin-top: 4px;
}

.mobileLabelValueFieldContainer {
  @extend .labelValueFieldContainer;
  margin-top: 10px;
}

.sendMessageContainer {
  margin-top: 28px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sendMessageContainerMobile {
  margin-top: 24px;
  margin-right: 16px;
}

.attachments {
  margin-top: 8px;
}

.attachment {  
  margin: 8px 8px 0px 0px;
}

.sendMessageTextFieldContainer {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-right: 24px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  div:first-child {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
  }
}

.sendMessageTextField {
  height: auto;
  overflow: auto;
  line-height: 20px;
  padding-right: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}

.sendMessageButtonContainerMobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 16px;
  height: 40px;
}

.addAttachmentIconMobile {
  margin-right: 16px;
  fill: $label-color;
  cursor: pointer;
}

.addAttachmentIcon {
  @extend .addAttachmentIconMobile;
  position: absolute;
  right: 16px;
  top: 12px;
  padding: 0px;
  margin-right: 0px;
}

.placeholder {
  border-radius: 2px;
  margin-left: 4px;
  height: 12px;
  width: 142px;
}

.datePlaceholder {
  @extend .placeholder;
  margin-left: 0px;
}

.summaryPlaceholder {
  height: 33px;
  width: 75%;
  border-radius: 2px;
}

.buttonContainer {
  width: 92px;
}

@media only screen and (min-width: 768px) {
  .placeholder {
    border-radius: 4px;
    height: 18px;
    width: 114px;
  }

  .summaryPlaceholder {
    border-radius: 4px;
  }

  .datePlaceholder {
    width: 175px;
  }
}

@media only screen and (max-width: 768px) {
  .sectionSummary {
    font-size: 18px;
  }

  .label {
    font-size: 14px;
  }

  .fieldValue {
    font-size: 14px;
  }
}
