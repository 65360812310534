$font-family: Roboto;
$font-color: #212121;

.container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 29px 40px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.containerNormal {
  @extend .container;  
  height: 470px;
  width: 504px;
}

.containerNormal div[class="CwDialog-wrap"] {
  overflow: visible !important;
}

.containerMobile {
  @extend .container;
  height: 400px;
  width: 100%;
  max-width: 500px;
  padding: 29px 15px 24px;
}

.containerMobile div[class="CwDialog-wrap"] {
  overflow: visible !important;
}

.topTextContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.topContainer {
  @extend .topTextContainer;
  height: 95%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.buttonContainer {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.screenMessage {
  width: 100%;
  margin-bottom: 16px;
}

.screenMessageMobile {
  @extend .screenMessage;
  margin-top: 16px;
}

.title {
  font-family: $font-family;
  color: $font-color;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
}

.subTitle {
  font-family: $font-family;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  padding-top: 5px;
}

.comboBox {
  width: 424px;
  height: 40px;
  padding-top: 48px;
}

.comboBoxMobile {
  @extend .comboBox;
  width: 100%;
}

.button {
  div {
    font-weight: normal;
  }  
}

.submitButton {
  @extend .button;
  margin-left: 10px;
}

.cancelButton {
  @extend .button;
  margin-right: 10px;
}
