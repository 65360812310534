
.container {
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow-y: auto;
  height: calc(100% - 60px);
}

.container .title {
  font-size: 26px;
  font-weight: 500;
  color: #212121;
}

.mobileNavClose {    
  z-index: 1;
  top: 0;
  left: 0;  
}

.cardContainer {
  padding: 0 10px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  > div,
  > div:first-child {
    border-style: none;
  }
}

.cardContainer > div:first-child {
  margin-top: 0;
}

.square {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50%;
  border: 1px solid #e6e6e6;
  padding: 16px 10px;
}

.parentClass {
  width: 50%;
  height: 24px;
  
  > div {
    border-radius: 4px;
  }
}

.mobileNavClose {    
  width: 100%; 
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.container .message {
  border-bottom: 1px solid #e6e6e6;
  padding: 0 20px 20px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}

.mobileNavClose {    
  z-index: 1;
  top: 0;
  left: 0;  
}

.cardContainer {
  padding: 38px 35px;
}

@media only screen and (min-width: 768px) {
  
  .cardContainer {
    -ms-flex-preferred-size: 550px;
        flex-basis: 550px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;    
  }
  .mobileNavClose {    
    width: 650px;   
  }
  .square {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    border-radius: 4px;
  }
  .container .message { 
    padding: 0 40px 20px;
  }
}

@media only screen and (min-width: 100px) and (max-width:600px) {
  .container {
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow-y: auto;
    height: calc(100% - 60px);
    width: 100%;
  }
  .cardContainer {
    width: auto;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .mobileNavClose {    
    width: 100%; 
  }
  .square {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    width: 330px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

.parentClass {

  > div {
    border-radius: 4px;
  }
  .cardContainer {
    -ms-flex-preferred-size: 550px;
        flex-basis: 550px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .mobileNavClose {    
    width: 650px;   
  }
  .square {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    width: 550px;
    height: 55px;
    border-radius: 4px;
    padding-right: 10px;
    padding-left: 10px;
  }
}