.banner {
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 24px;
}

.image {
  width: 24px;
  height: 24px;
  border: solid 2px #ffffff;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.secondImage {
  left: -10px;
}

.image div img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
}

.image div {
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
}

.imageLoading {
  width: 24px;
  height: 24px;
  margin: 2px 10px 0px 0px;
  -o-object-fit: contain;
     object-fit: contain;
}

.imageMessageIcon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #026ccf;
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

.imageMessageIconImage {
  width: 10px;
  height: 10px;
  margin: 3px;
}

.profileContainer {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.textLabel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.nameLabel,
.textLabel {
  padding-left: 5px;
  font-size: 16px;
  font-weight: bold;
  float: left;
  cursor: pointer;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.nameLabel {
  font-weight: bold;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}

.initials {
  font-size: 14px;
}

.nameLabelLoading {
  width: 50px;
  height: 16px;
  margin: 5px 10px 0px 0px;
}

.textLabelLoading {
  width: 124px;
  height: 16px;
  margin: 5px 0px 0px 0px;
}

.bioContainer {
  width: auto;
  height: auto;
  color: black;
  background-color: #ffffff;
  color: #212121;
  padding: 10px;
}

.flyoutContainer {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.flyoutContainer
  div[class='CwFlyout-flyout CwFlyout-flyoutTopRight CwDialog-modal'] {
  margin-top: 5px;
  padding: 10px;
  left: -54px !important;
  top: 47px !important;
}

.flyoutContainer
  div[class='CwFlyout-flyout CwFlyout-flyoutTopRight CwDialog-modal']:after {
  left: 45px;
  right: unset;
}

@media only screen and (min-width: 1300px) {
  .textLabelLoading {
    display: inline;
  }

}

@media only screen and (max-width: 768px) {
  .flyoutContainer
    div[class='CwFlyout-flyout CwFlyout-flyoutTopRight CwDialog-modal'] {
    margin-top: 20px;
    left: -45.5px !important;
    top: 42px !important;
    right: -22px !important;
  }

  .flyoutContainer
    div[class='CwFlyout-flyout CwFlyout-flyoutTopRight CwDialog-modal']:after {
    left: 60px;
    right: unset;
  }
}

@media only screen and (min-width: 1300px) and (max-width:1400px) {
  .nameLabel {
    width: 22%;
    display: inline-block;
  }
}