.licenseDrawer{
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: relative;

    > * {
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        width: 100%;
    }

    .titleContainer {
        padding: 0 38px;
        border-bottom: 1px solid #e6e6e6;
    }

    .contentContainer {
        padding: 0 38px;
        overflow: auto;
    }

    .contentUnAutohorizedContainer{
        background-color: #F5F5F5;
    }
}

@media only screen and (min-width:768px) {
    .licenseDrawer {
        width: 560px;

        .titleContainer,
        .contentContainer {
            padding: 0 62px;
        }
    }
}