.container {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.closingText {
    font-weight: 500;
    color: #212121;
}

.closingButtonContainer {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

.closingButton {
    font-size: 14px;
    font-weight: 500;
    width: 57px;
    height: 40px;
    line-height: 40px;
    color: #ca001a;
    text-align: center;
}

.closingButton:hover {
    color: #ca001a;
}

@media only screen and (min-width:768px) {

    .container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }

    .closingText {
        line-height: 40px;
    }

    .closingButton {
        font-size: 1em;
        width: 54px;
        margin-left: 0;
    }

    div[class="CwNotification-content"] {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
        
    div[class="CwNotification-text"] {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -ms-flex-item-align: center;
            align-self: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin-right: 0px;
    }
}