.container {
  margin: 20px 0 0 0;
  padding: 20px;
  width: 100%;
  border-top: 1px solid #e6e6e6;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.subtitle {
  font-size: 20px;
  color: #212121;
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
}

.table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.tableBody {
  overflow-y: auto;
  max-height: 162px;
}

.header,
.footer,
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid #e6e6e6;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.column {
  margin: 0 5px;
  padding: 5px 0 4px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.columnHeader {
  @extend .column;
  width: 155px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  color: #5e5e5e;
  vertical-align: bottom;
}

.columnData {
  @extend .column;
  min-width: 10%;
  color: #212121;
  font-size: 16px;
}

.columnFooter {
  width: 100%;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #212121;
  text-align: right;
  vertical-align: bottom;
  padding: 10px 5px 0 0;
}

.amount {
  text-align: left;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.retryPaymentContainer {
  width: 100%;
  text-align: left;
  margin-top: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.failedPaymentMessage {
  text-align: left;
  margin: 0 20px 20px 0;
}

.retryPaymentButton {
  margin: 20px 0 0;
  width: 100%;
}

.paymentStatus {
  font-size: 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 17px;
  position: relative;
  font-weight: normal;
}

.paymentFailedStatus {
  @extend .paymentStatus;
  color: #ca001a;
  font-weight: 500;
}

%before {
    display: inline-block;
    content: '';
    height: 12px;
    width: 12px;
    border-radius: 51%;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -6px;
}

.paymentStatus {
    &:before {
        @extend %before;
    }
}

.paymentFailedStatus:before {
  @extend %before;
  background-color: #ca001a;
}

.paymentPendingStatus {
  @extend .paymentStatus;
  color: #fa8500;
}

.paymentPendingStatus:before {
  @extend %before;
  background-color: #fa8500;
}

.loaderImageContainer {
  height: 30px;
  width: 30px;
  padding-left: 15px;
  padding-top: 5px;
}

.paymentFailedStatus,
.paymentPendingStatus {
  text-overflow: ellipsis;
}
.header {
  padding: 12px 10px;
}
.row {
  padding: 12px 10px;
  .failedPaymentListLabel {
    font-size: 14px;
  }
}

@media only screen and (min-width: 480px) {
  .header {
    padding: 12px 24px;
  }
  .row {
    padding: 12px 24px;
    .failedPaymentListLabel {
      font-size: 16px;
    }
  }
}

@media only screen and (min-width: 767px) {
  .retryPaymentButton {
    width: 170px;
    margin-bottom: 0;
  }
  .container {
    padding: 20px 40px;
  }
}

@media only screen and (min-width: 100px) and (max-width: 360px) {
  .container {
    margin-left: 0px;
  }
  .table {
    margin: 0;
    width: 100%;
  }
}
