.productTile {
  overflow: hidden;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  width: 45%;
  padding-right: 2px;
  margin-bottom: 40px;
}
.customStyle{
  overflow: hidden;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  width: 85%;
  padding-right: 2px;
  margin-bottom: 2px;
}

.styleSecondScreen{
  overflow: hidden;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  width: 38%;
  padding-right: 2px;
  margin-bottom: 2px;
}

.productName {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 2px;
  text-transform: uppercase;
  font-family: CeraPro;
  color: #22326E;
  &:hover{
    opacity: 0.8;
    color: #22326E;
  }
}

.tradeMarkStyle {
  font-size: 10px;
  margin-top: 20px;
  color: #22326E;
  font-weight: bold;
}

.productNameOverflow{
  display: inline-block;
  width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-left: -100%;
  margin-right: -100%;
}
      
.productInfo {
  font-size: 12px;
  color: #7d7d7d;
  line-height: 1.5;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  width:100%
}
  
.productStatus {
  position: absolute;
  min-width: 75px;
  left: 0;
  top: 0;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 1.33;
  padding: 2px 12px 2px 12px;
  border-radius: 4px 0px 10px 0px;
}
    
.productStatusInTrial {
  color: #000000;
  background-color: #e6e6e6;
}
  
.productStatusFree {
  color: #ffffff;
  background-color: #212121;
}
  
.productLogo {
  width: 80%;
  height: 42px;
  border-radius: 100%;
  padding: 8px;
  margin: 8px;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}
  
.productTile:hover .productLogo {
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}
  
.productTile:hover .productUnite,
.productTile:hover .productIdentify,
.productTile:hover .productManage,
.productTile:hover .productAutomate,
.productTile:hover .productSell,
.productTile:hover .productControl,
.productTile:hover .productVirtualCommunity,
.productTile:hover .productPartnerProgram,
.productTile:hover .productMarketPlace {
  background-color: inherit;
}
  
.productLogo svg {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0px;
  width: 142px !important;
  height: 42px !important;
  fill: #22326E;
}
  
@media only screen and (min-width: 768px) {
  .productTile {
      width: 33%;
  }
}