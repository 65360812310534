.container {
  background-color: #ffffff;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: calc(100% - 48px);
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  align-items: center;
  overflow: auto;
}

.titleName {
  font-size: 24px;
  font-weight: bold;
  color: #212121;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 400px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.viewTicket {
  margin-bottom: 40px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.image {
  width: 100%;
  height: 100%;
}


@media only screen and (min-width: 768px) { 
    .container {
        width: 600px;
    } 

}
 
@media only screen and (max-width: 475px) {
    .imageContainer {
        width: 317px;
        height: 275px;
    }

    .image {
        width: 100%;
    }
}
  