.container {
  width: 459px;
  overflow: auto;
  padding: 35px 40px;
}

.headertext {
  margin-top: 20px;
}

.contactUsButton {
  width: 200px;
  height: 36px;
  font-size: 14px;
}
