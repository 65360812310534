.header {
  padding-bottom: 15px;
  margin-top: 10px;
  border-bottom: 1px solid #e6e6e6;
}

.headerArrow {
  cursor: pointer;
}

.headerArrowDisabled {
  cursor: not-allowed;
  fill: #e6e6e6;
}

.headerArrowsContainer {
  width: 48px;
  height: 24px;
}

.headerArrowsContainer > div {
  display: inline-block;
}

.mainTitle {
  font-size: 24px;
  font-weight: bold;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  color: #212121;
  margin: 0 30px;
}

.placeholder {
  height: 40px;
  width: 50%;
  border-radius: 4px;
}

@media only screen and (min-width: 768px) {
  .header {
    margin-top: 0;
    padding-bottom: 25px;
  }

  .mainTitle {
    font-size: 2em;
  }
}
