.withPointer {
  cursor: pointer;
}

.description {
  font-size: 12px;
  text-align: left;
  color: #212121;
}

.managerContainer {
  -webkit-box-shadow: 0 2px 12px 0 rgba(40, 50, 66, 0.15);
          box-shadow: 0 2px 12px 0 rgba(40, 50, 66, 0.15);
  border-radius: 4px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 19.5px 24.5px;
  border-radius: 8px;
  margin: 20px 0;
}

.managerContainer:hover {
  -webkit-box-shadow: 0 2px 4px 3px rgba(33, 33, 33, 0.2);
          box-shadow: 0 2px 4px 3px rgba(33, 33, 33, 0.2);
}

.profileImage {
  width: 40px;
  height: 40px;
  margin-right: 0px;
  padding: 0 4px 11px 0;
  border: solid 2px #ffffff;
  border-radius: 50%;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.profileImage img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
}

.profileImage div {
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
}

.aboutMeContent {
  width: 100%;
  min-width: 200px;
  margin-left: 10px;
}

.toolTipIcon {
  height: 14px;
  width: 14px;
  padding: 6px 6px;
  fill: #267e9c;
  margin-left: 2px;
  vertical-align: middle;
}

.contactMethodIcon {
  height: 16px;
  width: 16px;
  fill: #267e9c;
  margin: 0 2px 0 0;
  -ms-flex-item-align: start;
      align-self: flex-start;
  justify-self: center;
}

.contactMethodLabel {
  color: #267e9c;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  margin: 0 6px 0 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-self: center;
}

.emailWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
}

.contactLinkWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  cursor: pointer;
}

.rectangle {
  height: 20.75px;
  width: 1px;
  background-color: #7d7d7d;
  margin: 0 16px;
  justify-self: center;
}

.showMoreShowLess {
  color: #267e9c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.resourceInfo {
  max-width: 80%;
  padding: 0 0;
}

.contactMethods {
  margin-top: 12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}

.aboutMeTitle {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
  margin-bottom: 6px;
}

.aboutMeDescription {
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.resourceCardLabel {
  font-size: 14px;
  line-height: 20px;
  padding: 3px 0 0;
  color: #212121;
  font-weight: bold;
  letter-spacing: 0;
}

.resourceCardTitleLabel {
  color: #7d7d7d;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
  margin-bottom: 8px;
}

.resourceCardDescription {
  color: #212121;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 3px 0;
  margin-bottom: 11px;
}

.nameRow {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contactMethod {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  -ms-flex-item-align: left;
      align-self: left;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.contactMethodMargin {
  margin-top: 10px;
}

@media only screen and (min-width: 768px) {
  .contactMethods {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
  }

  .managerContainer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .profileImage img {
    min-width: 64px;
  }

  .profileImage {
    width: 64px;
    height: 64px;
    border: solid 2px #ffffff;
    border-radius: 50%;
    padding: 8px 8px;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  .resourceCardLabel {
    font-size: 18px;
    line-height: 18px;
    padding: 3px 0;
  }

  .resourceCardTitleLabel {
    font-size: 12px;
    line-height: 18px;
  }

  .resourceHeader {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .resourceInfo {
    padding: 8px 8px;
  }
}

@media only screen and (max-width: 767px) {
  .aboutMeContent {
    width: 40vw;
    min-width: 40vw;
    max-height: 50vh;
    overflow: auto;
    -webkit-box-align: left;
        -ms-flex-align: left;
            align-items: left;
    text-align: left;
  }
}

@media only screen and (max-width: 475px) {
  .imageContainer {
    width: 317px;
    height: 275px;
  }

  .contactMethodMargin {
    margin-top: 0px !important;
  }

  .image {
    width: 100%;
  }

  .titleName {
    font-size: 18px;
    width: 219px;
  }

  .name {
    padding-left: 8px;
  }

  .successManagerLabel {
    padding-left: 8px;
  }

  .rectangle {
    height: 15.75px;
    width: 1.5px;
    background-color: #7d7d7d;
    margin: 5px 10px;
    justify-self: center;
  }

  .contactMethods {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .mediumLabel {
    font-size: 14px;
  }

  .smallLabel {
    font-size: 10px;
  }

  .productContainer {
    padding: 16px 16px;
  }

  .managerContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .resourceCardTitleLabel {
    font-size: 10px;
    line-height: 14px;
  }

  .toolTipIcon {
    padding: 3px 6px;
  }

  .contactMethods {
    margin-top: 4px;
  }

  .contactMethod {
    padding: 4px 0;
  }

  .contactMethodLabel {
    font-size: 10px;
    line-height: 11px;
    margin: 4px 0 0 0;
  }
}
