.radio-btn-group {
  margin-top: 30px;
}

.tableCell,
.creditCard {
  padding: 13px;
}

.creditCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: solid 2px #e6e6e6;
  border-radius: 4px;
  min-height: 56px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 0px 9px 12px;
}

.creditCard + .creditCard {
  margin-top: 8px;
}

.creditCard.selected {
  border: solid 2px #267E9C;
}

.creditCard.expired {
  border: solid 2px #CA001A;
}

.creditCard.expired .cardDetail label:nth-child(-n+2) {
  color: #CA001A;
}

.creditCard.standalone {
  padding: 8px 0px;
  border: none;
  margin: 0;
  line-height: 38px;
}

.creditCard iframe {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  padding-right: 20px;
  height: 920px;
  width: 100%;
  border: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #e6e6e6;
  margin-top: 15px;
}

.newCard.creditCard iframe {  
  margin-top: 0;
}

.cardActionsRight {
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.cvv {
  position: absolute;
  right: 28px;
  input {
    width: 60px;
    height: 32px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 10px;
    text-align: center;
  }
}

.editCard {
  width: 24px;
  height: 24px;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}

.card {
  display: inline-block;
  line-height: 3em;
}

.cardName {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.cardImage {
  padding-left: 5px;
  height: 24px;
  min-width: 39.23px;
  width: auto;
}

.cardImage.standalone {
  padding-left: 0px;
}

.cardName label {
  padding-left: 7px;
}

.cardName div[class~="CwRadioButton-controlIndicator"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.cardName div[class~="CwRadioButton-controlIndicator"]:after {
  top:7px !important;
  left: 7px !important;
}

.newCard {
  border: none;
  padding: 0;
  margin-top: 20px;

  .cardLoading {
    margin-left: 14px;
  }
}

.newCard.rowReverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.cardLoading {
  height: 25px;
  width: 50%;
  -ms-flex-item-align: center;
      align-self: center;
}

.editCardFlyoutContainer div[class~="CwFlyout-flyout"] {
  width: 240px;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.editCardOptions {
  font-size: 16px;
  text-align: left;
  padding: 12px 16px;
  height: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.editCardOptions:hover {
  background-color: rgba(2, 108, 207, 0.1);
}

.defaultCard {
  text-align: left;
  color: #b0b0b0;
  font-size: 12px;
}

@media only screen and (min-width: 769px) {
  .wallet {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    margin-top: 8px;
  }
  .addNewCard {
    width: 170px;
  }
}

@media only screen and (min-width: 480px) {
  .cardDetail {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .creditCard {
    padding: 14px;
  }

  .creditCard.newCard {
    padding: 0;
    margin-bottom: 0;
    margin-top: 40px;
  }

  .defaultCard {
    font-size: 16px;
    padding-left: 20px!important;
  }

  .cardName label {
    padding-left: 10px;
  }

  .cardImage {
    padding-left: 12px;
  }

  .cvv {
    right: 40px;
  }

  .editCard {
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
  }

  .creditCard iframe {
    padding: 10px;
  }
}

@media only screen and (min-width:320px) and (max-width: 479px) {
  .addNewCard {
    width: 100%;  
  }
}