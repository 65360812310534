.wrap {
  padding-top: 20px;
  display: grid;
}

.button {
  width: 110px;
  font-size: 14px;
  padding: 0px 16px;
}

.dialog {
  width: 504px;
  height: auto;
}

.emailContainer {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}

.emailField {
  padding-left: 16px;
  padding-right: 40px;
  background-color: white;
  height: 40px;
}

.emailhelptext {
  margin-top: 10px;
}

.emailIconKnownIssue {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 30px;
  width: 30px;
  fill: #b0b0b0;
}

.validEmail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: green;
  margin-top: 20px;
}

.invalidEmail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: gray;
  margin-top: 20px;
}

.validEmailIcon {
  fill: green;
}
.invalidEmailIcon {
  fill: gray;
}

.validateEmailLabel {
  margin-left: 5px;
}

.sendlink {
  height: 36px;
  width: 107px;
  color: #212121;
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  display: grid;
  justify-self: center;
  margin-top: -19px;
}

.dialogbuttons {
  justify-self: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 45%;
  margin-top: 30px;
}

@media only screen and (max-width: 480px) {
  .button {
    width: 120px;
    height: 40px;
    margin-left: 3px;
  }
}

@media only screen and (max-width: 767px) {
  .button {    
    margin-left: 3px;
  }
}
