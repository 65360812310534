

.licenseInfo {
    padding-top: 36px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: auto;

    .labelDark,
    .labelStandard {
        color: #212121;
        font-weight: bold;
        margin-bottom: 6px;
    }

    .labelDark {
        font-size: 16px;
    }

    .labelStandard {
        font-size: 14px;
    }

    .labelLight {
        font-size: 14px;
        color: #4a4a4a;
        margin-bottom: 7px;
    }

    .licensePriceTag {
        font-size: 24px;
        font-weight: bold;
        color: #026ccf;
        margin-right: 4px;
    }

    .licensePrice {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        font-size: 14px;
    }

    .productLicenseQuantity {
        margin: 48px 0 36px;

        .licenseQuantityDropDown {
            width: 134px;
            height: 40px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            margin-top: 2px;
            border-radius: 4px;
            border: solid 1px #b0b0b0;
            padding: 8px 16px;
            color: #026ccf;
            font-weight: normal;

            input {
                height: 40px;
            }

            svg[class~="CwTriggerField-trigger"] {
                top: 11px;
            }
        }
    }

    .labelText {
        font-size: 14px;
        line-height: 1.29;
        color: #212121;
        margin-top: 7px;

        span {
            color: #026ccf;
        }
    }
 
    .licenseSuccessimage {
        margin-bottom: 24px;

        img {
            width: 100%;
        }
    }

    .tncBox {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

    .labelTnc {
        font-size: 12px;
        color: #0069d2;
        margin-left: 10px;
    }

}

.licenseInfo.licenseStep2{
    .licensePriceTag {
        margin-bottom: 8px;
    }

    .labelText {
        margin: 0 0 24px;
    }
}

.licenseInfo.licenseStep3 {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;

    img {
        width: 100%;
    }
}

.licenseInfo.licenseInfoError {
    .labelQuote {
        color: #212121;
        font-size: 18px;
        line-height: 24px;
    }

    .labelQuote.labelContactManager {
        font-size: 16px;
        text-align: center;
    }

    .productLicenseQuantity {
        margin: 25px 0 36px;
    }

    .labelStandard {
        font-size: 18px;
        color: #4a4a4a;
    }

    .commentTextArea {
        height: 144px;
        width: 247px;
    }

    .labelErrorCode {
        color: #ccc;
        margin-top: 24px;
    }

    .labelQuoteSecondary {
        color: #212121;
        font-size: 18px;
        line-height: 24px;
        margin-top: 25px;
    }

}

@media only screen and (min-width:768px) {

    .licenseInfo {
        padding-top: 36px;
        
        .labelDark {
            font-size: 18px;
        }

        .labelStandard {
            font-size: 16px;
        }

        .labelText.lastStepLabel {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }

    .licenseInfo.licenseStep2{
        .licensePriceTag {
            margin-bottom: 32px;
        }
    
        .labelText {
            margin: 0 0 24px;
        }
    }

    .licenseInfo.licenseInfoError {
        .commentTextArea {
            width: 345px;
        }

        .labelQuote.labelContactManager {
            font-size: 18px;
        }
    }
}