.mfaContainer {
  margin-top: 15px;
}
  
.textfield {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 15px;
  border: solid 1px #b0b0b0;
  background-color: #ffffff;
}
  
.mfaEmailHeader {
  font-size: 14px;
  font-weight: bold;
  line-height: 2.75;
  color: #212121;
}
  
.wrappedEmail {
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
  
.subHeaderDescription {
  margin-bottom: 24px;
}

.emailMfaContainer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  
  div[class~='CwFlyout-flyout'] {
    width: 177px;
    font-size: 14px;
    color: #373737;
    padding: 0;
  
    Label {
      cursor: pointer;
      padding: 12px 16px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
  
      &:hover {
        background-color: #e3f1fc;
      }
    }
  }
}
  
.mfaDisabled {
  fill: #ed9324;
}
  
.mfaWarning {
  background-color: #ffebdd;
  padding: 18px 32px;
  position: relative;
  margin-top: 25px;
  
  .warningIcon {
    width: 18px;
    position: absolute;
    top: 15px;
    left: 8px;
    fill: #ed9324;
  }
  
  .warningCancelIcon {
    position: absolute;
    right: 8px;
    top: 3px;
    fill: #212121;
    width: 16px;
    cursor: pointer;
  }
  
  .warningIconRed {
    fill: #ca001a;
  }
  
  .warningIconBlue {
    fill: #026ccf;
  }
  
  .mfaWarningText {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
  
.emailMfaConfirmation {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  
  .mfaButton {
    height: 24px;
    margin-left: 25px;
    color: #ed9324;
  
    &.mfaButtonRed {
      color: #ca001a;
    }
  }
}
  
.emailContainer {
  width: 85%;
}
  
@media only screen and (min-width: 768px) {
  .mfaEmailHeader {
    font-size: 16px;
  }
  
  .Mfa-textfield {
    width: 90%;
  }
  
  .emailContainer {
    width: 100%;
  }
}
  