.screen {
  background-color: #F5F5F5;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.container {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 90px;
}

.text {
  font-family: Roboto;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #7D7D7D;
  margin-bottom: 20px;
}

.image {
  height: 300px;
  width: 300px;
}

.screen::-webkit-scrollbar {
  width: 8px;
}
.screen::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.screen::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
}