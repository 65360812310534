.loadButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 44px;
  background-color: #267E9C;
  text-align: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: #267E9C;
}

.load {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 5px solid #fff;
  border-radius: 100%;
  margin: 6px;
}
