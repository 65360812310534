.wrap {
  border-bottom: 1px solid #E6E6E6;
  padding-left: 32px;
  padding-top: 24px;
  padding-bottom: 25px;
  padding-right: 55px;
}

.details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.initials {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.placeholder1 {
  border-radius: 2px;
  margin-left: 16px;
  height: 12px;
  width: 142px;
}

.placeholder2 {
  border-radius: 2px;
  margin-left: 16px;
  height: 12px;
  width: 142px;
  margin-top: 3px;
}

.placeholder3 {
  border-radius: 4px;
  margin-left: 48px;
  height: 16px;
  margin-top: 6px;
}

.placeholderContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .placeholder1 {
    border-radius: 4px;
    height: 18px;
    width: 114px;
  }

  .placeholder2 {
    margin-top: 1px;
    height: 18px;
  }

  .placeholder3 {
    margin-top: 4px;
    height: 18px;
  }
}
