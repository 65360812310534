.container {
  text-align: center;
  width: calc(100% - 40px);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 20px;
}

.scrollable {
  height: calc(100% - 49px);
  overflow-y: auto;
  overflow-x: hidden;
}

.plan {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin:12px
}

.planCard {
  min-width: 200px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
  padding: 30px;
  text-align: left;
  border-radius: 10px 10px 0 0;
  min-height: 160px;

  
  .planCardControlButton {
    border-radius: 4px;
    -ms-flex-item-align: center;
        align-self: center;
    margin-bottom: 30px;
    width: 170px;
    font-size: 16px;
  }

  .planCardControlButton,
  .planCardControlButton:hover {
    background-color: #ca001a;
    border-color: #ca001a;
  }
}

.planCard.support {
  background-image: url('/images/HomePage/control-support.jpg');
  background-repeat: round;
}

.planCard.access {
  background-image: url('/images/HomePage/control-access.jpg');
  background-repeat: round;
}

.planCardLabelBilling {
  font-size: 14px;
  color: #ffffff;
}

.comparePlan {
  padding: 10px;
  font-size: 16px;
}

.connectPlan {
  padding: 10px;
  font-size: 12px;
}

.buttonContainer {
  width: 360px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px;
}

.requestHelp {
  padding: 12px;
  font-weight: 500;
}

.featureCheckIcon {
  width: 17px;
  height: 16px;
  fill: #ca001a;
}

.featureLabel {
  padding: 0 8px;
  text-align: left;
  font-size: 14px;
  margin-bottom: 17px;
}

.feature {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.featureBottomLabel {
  padding: 0 8px;
  text-align: left;
  margin-top: 19px;
  font-weight: 500;
}

.featureImage {
  width:100%
}

.planCardLabel {
  padding: 4px 0;
  color: #ffffff;
}

.planCardName {
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  margin: -8px 0 -5px;
  color: #ffffff;
}

.planCardDescription {
  width: 80%;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}

.planCardPricingLabel {
  font-size: 12px;
  color: #ffffff;
}

.planCardFeatureCondition {
  font-size: 12px;
  margin-top: 18px;
}

.planCardPrice {
  font-size: 36px;
  color: #ffffff;
}

.planCardPriceInterval {
  font-size: 18px;
  color: #ffffff;
}

.planCardStatus {
  min-width: 90px;
  height: 20px;
  padding: 0px 8px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.7;
  text-align: center;
  color: #ca001a;
  border-radius: 4px;
  background-color: #ffffff;
  overflow: hidden;
  margin: 10px 0 0 15px;
}

.productStatusRed {
  color: #ffffff;
  background-color: #ca001a;
}

.productStatusGreen {
  color: #ffffff;
  background-color: #008000;
}

.loadButton {
  background-color: #ca001a;
  border-color: #ca001a;
}

.cards {
  width: 100%;
}

.disableButtonTitle {
  height: 35px;
  position: absolute;
  top: 180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: calc(100% - 60px);
}

.productIconHeader {
  width: 271px;
  height: 60px;
  fill: #22326E;
}

.dividerLineHeader {
  width: calc(100% + 200px);
  margin: 20px 0px;
  border-bottom: 1px solid #e6e6e6;
}

@media only screen and (min-width: 960px) {
  .container {
    max-width: 920px;
  }

  .planContainer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    & div:last-child {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
  }

  .planCard {
    min-width: 288px;
    padding: 40px;
    text-align: left;
    border-radius: 10px 10px 0 0;
    
    .planCardControlButton {
      margin-bottom: 45px;
    }
  }

  .disableButtonTitle {
    top: 210px;    
    width: calc(100% - 80px);
  }

  .plan {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border-radius: 10px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    margin: 30px 32px;
  }

  .support {
    background-image: url('/images/HomePage/control-support@2x.jpg');
    background-repeat: round;
  }

  .access {
    background-image: url('/images/HomePage/control-access@2x.jpg');
    background-repeat: round;
  }

  .planCardName {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .planCardLabel {
    padding: 4px 0;
  }

  .planCardStatus {
    margin: 20px;
  }
  
  .featureLabel {
    padding: 0 8px;
    text-align: left;
    line-height: 1.5;
  }

  .productIconHeader {
    width: 335px;
    height: 72px;
  }
}
