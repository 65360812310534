
.mobileNavClose {    
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;

    > div:first-child{
        background-color: #267E9C;

        svg {
            fill: #fff;
        }
    }    
}

.menuContainer {
    height: 100%;
}

.mobileMenuList {
    height: 100%;

    .mobileMenuUpperContainer {
        overflow-y: auto;
    }
    .mobileMenuUpperList,
    .mobileMenulowerList {
        padding: 16px 0 0 10px;
        background-color: #fafafa;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        .mobileNavLink {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;
            width: 100%;
            height: 48px;
            line-height: 48px;
            padding-left: 35px;
            margin-bottom: 16px;
            cursor: pointer;
            color: #b0b0b0;
            text-transform: capitalize;
            text-decoration: none;           

            svg {
                fill: #b0b0b0;
                margin-right: 15px;
            }

            div[class="CwThemeBackgroundColor Avatar-container"] {
                width: 24px;
                height: 24px;
                font-size: 14px;
                margin-right: 15px;
            }
        }

        .mobileNavLink.selectedLink,
        .mobileNavLink:hover {
            color: #267E9C;
            background-color: #f5f5f5;

            svg {
                fill: #267E9C;
            }
        }
        
    }

    .mobileMenuUpperList {
        overflow: hidden;
    }

    .scrollable::-webkit-scrollbar {
        width: 8px;
    }
    
    .scrollable::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }
    
    .scrollable::-webkit-scrollbar-thumb {
        background-color: #cccccc;
        border-radius: 10px;
    }

    .mobileMenulowerList {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}

