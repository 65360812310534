.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  max-height: 300px;
  width: 500px;
  background-color: #FFFFFF;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 25px 32px 14px 32px;
  -webkit-box-shadow: 1px 1px 3px #9a9a9a;
          box-shadow: 1px 1px 3px #9a9a9a;
}

.container:before {
  content: "";
  position: absolute;
  right: calc(100% - 8px);
  top: calc(100% - 30px);
  height: 15px;
  width: 15px;
  background: #FFFFFF;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  border-top: transparent;
  border-right: transparent;
  border-bottom: inherit;
  border-left: inherit;
}

.title {
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  margin-bottom: 24px;
  color: #212121;
}

.briefing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  overflow: auto;
}

.detail {
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
  margin-left: 15px;
}

.whoAmI {
  margin: 0 0 0 17px;
  list-style: none;
}

.name{
  font-size: 16px;
  padding-bottom: 0;
}

.securityRole{
  font-size: 14px;
  color: #7d7d7d;
  line-height:16px;
}

.companyName {
  font-weight:bold;
}

.role {
  color: #7d7d7d;
  font-size: 14px;
  line-height: 1.14;
}

.email {
  color: #267E9C;
  cursor: pointer;
  width:80%;
  text-decoration:none;
  line-height: 1;
  font-size: 16px;
}

.phone {
  line-height: 2;
}
ul li {
  font-size: 14px;
  padding-bottom: 17px;
}
