.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.titleWrapper {
  padding-bottom: 4px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.postDescription {
  font-size: 14px;
  padding: 17px 0 4px;
}

.postDescription > p {
  margin-top: 0;
  margin-bottom: 0;
}

.image {
  width: 100%;
  margin-top: 16px;
  -o-object-fit: contain;
     object-fit: contain;
  max-height: 760px;
}

.videoContainer {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;
  margin-top: 16px;
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.newsItemDate {
  font-size: 10px;
  padding: 0 4px;
  margin-left: auto;
}

.postInfoContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.authorContainer {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.authorImage {
  width: 24px;
  height: 24px;
  font-size: x-small;
  margin-right: 4px;
}

.productImage{
  width: 151px;
  height: 26px;
  fill: #22326e
}

.authorName {
  font-size: 12px;
  padding: 4px;
  font-weight: 500;
}

.authorTitle {
  font-size: 12px;
}

.callToActionButton {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-right: auto;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

.actionsContainer {
  margin-top: 30px;
}

.actionIconsContainer {
  margin-top: 20px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.actionIcon {
  width: 128px;
  margin: 8px 1px;
  font-size: 14px;
}

.actionIcon.unlike {
  color: #7d7d7d;
}

.actionIcon.like {
  color: #267E9C;
}

.icobutton {
  font-size: 15px;
  position: relative;
  cursor: pointer;
  margin-bottom: 4px;
  margin-left: -12px;
  padding: 0;
  border: 0;
  background: none;
  overflow: visible;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icobutton.unlike {
  color: #7d7d7d;
}

.icobutton.like {
  color: #267E9C;
}

.icobutton:hover,
.icobutton:focus {
  outline: none;
}

.actionBookmarkIcon {
  width: 16px;
  height: 16px;
  margin: 6px 0;
  path:nth-of-type(1) {
    fill: #7d7d7d;
  }
  cursor: pointer;
}

.actionBookmarkIcon.bookmark path:nth-of-type(1),
.actionBookmarkIcon.unbookmark:active path:nth-of-type(1),
.actionBookmarkIcon.unbookmark:hover path:nth-of-type(1) {
  fill: #267E9C;
}

.author {
  font-size: 14px;
  font-weight: 500;
}

.productImageBackground{
  background-color: #e6e6e6;
  color: #7d7d7d;
}

.authorName {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #22326E;
  white-space: normal;
  font-family: CeraPro;
}

.tradeMarkStyle {
  font-size: 10px;
  margin-top: 20px;
  color: #22326E;
  font-weight: bold;
}

@media only screen and (max-width:820px) {
  .callToActionButton {
    width: auto;
    height:auto;
  }
  }

@media only screen and (min-width: 768px) {
  .actionsContainer {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-top: 16px;
  }

  @media only screen and (min-width:821px) {
    .callToActionButton {
      width: auto;
    }
    }

  .actionIconsContainer {
    margin-top: 15px;
  }

  .newsItemDate {
    -ms-flex-item-align: end;
        align-self: flex-end;
    margin-left: auto;
    padding: 0;
  }

  .author {
    font-size: 14px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 1024px) {
  .title {
    font-size: 21px;
  }

  .postDescription {
    font-size: 16px;
  }

  .author {
    font-size: 14px;
    font-weight: 500;
  }

  .newsItemDate {
    font-size: 12px;
    -ms-flex-item-align: end;
        align-self: flex-end;
    margin-left: auto;
    padding: 0;
  }
}

@media only screen and (max-width: 475px) {
  .author{
    font-size: 14px;
    font-weight: 500;
  }
}
