.wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  padding: 12px 24px;
  border-radius: 2px;
  border: 1px  #e8e8e8;
  border-bottom-style: solid;
}

.wrap:first-child {
  border-top-style: solid;
  margin-top: 16px;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.stacked,
.stackedColumn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.placeholder {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.one {
  border-radius: 4px;
}

.two {
  border-radius: 4px;
  height: 18px;
  width: 80%;
  margin-left: 30px;
}

.three {
  border-radius: 2px;
  height: 16px;
  width: 77px;
  margin-top: 12px;
  margin-left: 30px;
}

@media only screen and (max-width: 768px) {
  .stackedColumn {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}