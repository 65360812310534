.knownIssuesColumnSmallWidth {
  min-width: 8%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.knownIssuesOptionColumn {
  min-width: 2%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.knownIssuesProductColumnData,
.knownIssuesProductColumnHeader {
  width: 16%;
  min-width: 90px;
  max-width: 16%;
}

.knownIssuesProductColumn {
  min-width: 32%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.fullWidth {
  width: 100%;
}

.titleColumnData {
  font-weight: 500;
  color: #267E9C;
  cursor: pointer;
}

.summaryColumn,
.summaryColumnHeader {
  width: 84%;
  min-width: 140px;
  max-width: 84%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.summaryColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.knownIssuesStatusColumnMediumWidth {
  min-width: 11%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.headerItem {
  cursor: pointer;
  white-space: nowrap;
  color: #267E9C;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-inline-size: -webkit-fit-content;
  max-inline-size: -moz-fit-content;
  max-inline-size: fit-content;
}

.actionIcon {
  fill: #267E9C;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.cursorPointer {
  cursor: pointer;
  width: 100%;
}

.knownIssuesLoader {
  margin: 6px 5px 5px !important;
  height: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: unset !important;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.knownIssueStatusMobile {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 17px;
  position: relative;
  cursor: pointer;
  overflow-wrap: anywhere;
  padding-right: 5px;
}

.knownIssueStatus {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 17px;
  position: relative;
  cursor: pointer;
  overflow-wrap: anywhere;
  padding-right: 5px;
}

.knownIssueStatus:before {
  display: inline-block;
  content: '';
  height: 12px;
  width: 12px;
  background-color: #549c05;
  border-radius: 51%;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -6px;
}

.knownIssueStatus.backlog:before {
  background-color: #7d7d7d;
}

.knownIssueStatus.development:before {
  background-color: #f5b633;
}

.knownIssueStatus.testing:before {
  background-color: #eb7b18;
}

.knownIssueStatus.needsInfo:before {
  background-color: red;
}

.knownIssueStatus.pendingRelease:before {
  background-color: #267E9C;
}

.knownIssueStatus.closed:before {
  background-color: #549c05;
}

.knownIssueStatus.unknown:before {
  background-color: grey;
}

.issueButton {
  border-radius: 27px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 84px;
  height: 44px;
  padding: 0 10px;
}

.summary {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.learnMoreLink {
  width: 40%;
  padding-right: 5px;
  cursor: pointer;
}

.mobileColumn {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  width: 100%;
  color: #212121;
}

.knownIssueDrawer {
  height: 100%;
  position: relative;
}

.previewLoading {
  height: 200px;
  margin: 0 25px;
}

.titleContainer {
  -ms-flex-line-pack: center;
      align-content: center;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 25px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #212121;
  margin: 0 30px;
}

.summaryContainer {
  padding: 24px 27px 15px 27px;
  border-bottom: 1px solid #e6e6e6;
}

.summaryContent {
  font-size: 1.3em;
  color: #212121;
  font-weight: bold;
  line-height: 1.13;
  padding-bottom: 15px;
}

.issueHighlights {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.labelContainer {
  padding-bottom: 8px;
}

.label {
  font-weight: bold;
}

.mobileLabel {
  line-height: 24px;
}

.footerContainer {
  width: 100%;
  height: 72px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  text-align: center;
  padding: 11px 0;
  bottom: 50px;
  border-top: 1px solid #e6e6e6;
}

.scrollableArea {
  width: 100%;
  overflow: auto;
  background-color: #fafafa;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 35px 27px;
  line-height: 25px;
}

.listView {
  height: 100%;
}

.statusScrollableArea {
  height:250px;
  overflow-y: scroll;
}

.listItemBulletPoint {
  margin-left: 14px;
  list-style-type: unset;
}

.unauthorizedContentContainer {
  background-color: #f5f5f5;
  height: inherit;
  padding: 90px 62px 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.knownIssueUnauthorizedText {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.textButton{
  background: none;
  border: none;
  color: blue;
  font: inherit;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: -6px;
  margin-left: -4%;
  padding: 0;
}
.textButton:hover{
  color:darkblue ;
}

.knownIssueUnauthorizedImage {
  height: 300px;
  width: 300px;
}

.mobileHeader {
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  color: #5e5e5e;
}

.mobileHeader > div {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.leftSideDetailsContainer {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 70%;
  cursor: pointer;
}

.rightSideDetailsContainer {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 30%;
}

.flyoutContainer {
  margin-left: 5px;
  position: relative;
}

.statusInfoFlyout {
  font-weight: normal;
  text-transform: none;
  width: 350px;
  margin-top: 8px;
  padding: 17px 24px 0 24px;
}

.knownIssueStatusInfoFlyout {
  font-weight: normal;
  text-transform: none;
  width: 350px;
  margin-top: 8px;
  padding: 14px 2px 0 14px;
}

.informationIcon {
  height: 16px;
}

ul li {
  font-family: Roboto;
  font-size: 14px;
  padding-bottom: 6px;
}

@media only screen and (min-width: 768px) {
  .knownIssueDrawer {
    width: 768px;
  }

  .previewLoading {
    width: 715px;
  }
}

.noIssuesImage {
  width: 250px;
  height: 265px;
}

.noIssues {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 24px;
  text-align: center;
}

.noIssuesLabel {
  font-size: 24px;
  font-weight: 500;
  color: #212121;
}

.inlineFlex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.helpIcon {
  margin-left: 5px;
  margin-top: -2px;
  cursor: pointer;
}

.defectprioritylabel {
  margin-left: 3px;
  font-weight: bold;
}

.inlineFlexDisplay {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.notesText {
  margin-top: 5px;
  margin-left: 48px;
  white-space: pre-wrap;
  word-break: break-word;
}

@media only screen and (min-width: 550px) {
  .noIssuesImage {
    width: 486px;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .knownIssuesLoader {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 35%;
            flex: 0 1 35%;

    > div {
      width: 100%;
      min-width: 100%;
    }
  }
}

@media only screen and (min-width: 1100px) {
  .largeSizeScreen {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .knownIssuesProductColumn,
  .knownIssuesProductColumnContainer {
    width: 20%;
    min-width: 100px;
    max-width: 20%;
  }

  .issueButton {
    border-radius: 27px;
    width: 74px;
    height: 28px;
    padding: 0
  }

  .summaryColumn {
    padding: unset;
    width: unset;
    min-width: unset;
    max-width: unset;
    -webkit-box-flex: unset;
        -ms-flex: unset;
            flex: unset;
    padding-right: 5px;
  }

  .summaryColumnHeader {
    display: none;
  }

  .itemsDirection {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .knownIssuesProductColumnData {
    width: unset;
    min-width: unset;
    max-width: unset;
  }
}

.menu {
  width: 177px;
  padding: 0px;
}

.menuItem {
  padding: 12px 16px;
  font-size: 0.875em;
  color: #373737;
  cursor: pointer;
}
.menuItem:hover {
  background-color: #e3f1fc;
}

//iPhone XR
@media only screen 
  and (device-width : 414px) 
  and (device-height : 896px) 
  and (-webkit-device-pixel-ratio : 2) {
    .listView :nth-child(2)> div{
      z-index: 0 !important;
    }
}

//iPhone 12 Pro
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .listView :nth-child(2)> div{
      z-index: 0 !important;
    }
}