
.licenseTitle {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;

    .title {
        line-height: 1.5;
        font-size: 20px;
        font-weight: bold;
        color: #252525;
        height: auto;
        text-align: center;
    }

    .title.onlyTitle {
        line-height: 1.25;
        margin-bottom: 34px;
    }

    .subTitle {
        font-size: 14px;
        line-height: 1.29;
        color: #4a4a4a;
        margin-top: 8px;
        text-align: center;
    }

    .stepStageConatiner {
        margin: 12px 0 18px;
        width: 245px;
    }
}

@media only screen and (min-width:768px) {
    .licenseTitle {
        .title {
            font-size: 24px;
        }

        .subTitle {
            font-size: 16px;
            line-height: 1.13;
            margin-top: 0;
        }

        .stepStageConatiner {
            margin: 24px 0 32px;
            width: 330px;
        }
    }
}