.container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 29px 40px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.containerNormal {
  @extend .container;
  height: auto;
  width: 494px;
}

.containerMobile {
  @extend .container;
  width: 100%;
  padding: 29px 30px 24px;
  -webkit-box-align: initial;
      -ms-flex-align: initial;
          align-items: initial;
}

.topContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 414px;
}

.topContainerMobile {
  @extend .topContainer;
  width: 100%;
}

.buttonContainer {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-top: 28px;
}

.headerTitle {
  color: #212121;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
}

.button {
  margin-left: 10px;
}

.formField {
  padding-top: 20px;
  label:first-child {
	  color: #4A4A4A;
  }
}

.labelHint {
  font-size: 10px;
  font-weight: 500;
  text-align: right;
  color: #212121;
}

.textField {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #b0b0b0;
  background-color: #ffffff;
}

.textAreaField {
  @extend .textField;
  height: 96px;
}

.testLink {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  color: #026ccf;
  padding: 10px 10px 0px 0px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.webImage {
  height: 24px;
  width: 24px;
  margin-right: 4px;
}

.screenMessage {
  width: 100%;
  margin-bottom: 10px;
}

.screenMessageMobile {
  @extend .screenMessage;
  margin-top: 10px;
}

.leftWrapper {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.buttonDelete {
  line-height: 1.8;
  cursor: pointer;
}

.deleteImage {
  width: 16px;
  margin-right: 3px;
}

.deleteLabel {
  font-size: 14px;
  font-weight: 500;
  cursor: inherit;
}

.contentMessage {
  height: 52px;
  margin: 12px 0 13px;
  font-size: 16px;
}

.contentMessageFull {
  @extend .contentMessage;
  width: 414px;
}

.contentMessageMobile {
  @extend .contentMessage;
  width: auto;
}

/* Loading style */

.loadingOne {
  width: 20%;
  height: 17px;
  margin: 20px 0 5px;
}

.loadingTwo {
  height: 40px;
}

.loadingThree {
  height: 40px;
  margin-bottom: 35px;
}

.loadingFour {
  height: 96px;
  margin-bottom: 12px;
}