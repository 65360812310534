.wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: auto;
  border: 1px solid #CCCCCC;
  border-top-style: none;
  border-radius: 0px 0px 4px 4px;
  margin-top: 0px !important;
}

.checkBox {
  margin-left: 8px;
  margin-bottom: 8px;
}

.subItemCheckBox {
  margin-left: 28px;
  margin-bottom: 8px;
}

.search > input {
  border-radius: 4px 4px 0px 0px;
  padding-left: 34px;
}

.category {
  background-color: #F5F5F5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.help
{
  margin-left: 6px;
  height: 15px;
  width: 15px;
  cursor: help;
}