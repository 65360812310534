.dialog {
  max-width: 393px;
  max-height: 310px;
}
.labels {
  height: 24px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.71;
  color: #4a4a4a;
  padding: 20px 0 10px 0;
}
.regionText {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.square {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50%;
  border: 1px solid #e6e6e6;
  padding: 16px 10px;
}

.parentClass {
  width: 50%;
  height: 24px;
  
  > div {
    border-radius: 4px;
  }
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .square {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    border-radius: 4px;
  }
}

@media only screen and (min-width: 100px) and (max-width:600px) {
  .square {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    width: 330px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

.parentClass {

  .square {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    width: 550px;
    height: 55px;
    border-radius: 4px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.regionLabel {
  font-size: 11px;
}
