.securityPageContentContainer {
  width: 100%;
  background-color: #ffffff;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0px 35px;
}

.previewLoading {
  height: 200px;
  width: 768px;
  margin: 0 25px;
}

.unauthorizedContentContainer {
  width: 600px;
  background-color: #f5f5f5;
  padding: 0 62px;
  height: inherit;
}

@media only screen and (min-width: 600px) {
  .securityPageContentContainer {
    width: 768px;
  }
}
