.wrap {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: relative;
  outline: none;
  width: 32px;
  height: 32px;
}

.container {
  width: 56px;
  height: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  color: #FFFFFF;
}

.popover {
  margin-left: 30px;
  z-index: 1;
}

.avatar {
  width: 56px;
  height: 56px;
}